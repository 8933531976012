import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DataTable from "components/Table";
import AppPagination from "components/AppPagination";
// import styles from "./style.scss";
import historyApi from "api/historyApi";
import Global from "general/Global";
import { useDispatch, useSelector } from "react-redux";
import ToastHelper from "general/helpers/ToastHelper";
import Utils from "general/utils/Utils";
import useSort from "hooks/useSort";
import CheckBox from "components/CheckBox";
import classnames from "classnames/bind";
import AppTabs from "components/AppTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
// const cx = classnames.bind(styles);

function TableListHistory(props) {
  const { t } = useTranslation();

  // MARK: --- Params ---
  const { searchText, selectedArea, dates } = props;
  const [page, setPage] = useState(1);
  const [step, setStep] = useState(10);
  const [logs, setLogs] = useState([]);
  const [total, setTotal] = useState(20);
  const [order, setOrder] = useState(["id", "ASC"]);
  const [sortBy, setSortBy] = useState();
  const [isLoading, setIsLoading] = useState(true);
  // const { directionIndicator, sortOnClick } = useSort(logs, (sortedArray) =>
  //     setLogs(sortedArray)
  // );

  // MARK: --- Functions ---
  async function getHistory() {
    setIsLoading(true);
    const res = await historyApi.getLogs({
      startDate:
        dates[0] !== "--/--/----" ? dates[0].format("YYYY-MM-DD HH:mm:ss") : "",
      endDate:
        dates[1] !== "--/--/----" ? dates[1].format("YYYY-MM-DD HH:mm:ss") : "",
      MaKhuVuc: selectedArea?.MaKhuVuc,
      orderField: order[0],
      orderMode: order[1],
      q: searchText,
      page: page - 1,
      limit: step,
    });
    const { TrangThaiGui, NoiDung } = res.data;
    if (TrangThaiGui == 0) {
      // console.log(res?.data.logs)
      setLogs(() => NoiDung?.Logs);
      setTotal(() => NoiDung?.count);
    }
    setIsLoading(false);
  }

  function dateFormat(date) {
    return moment(date).format("HH:MM - DD/MM/YYYY");
  }

  function changeOrder(orderBy) {
    if (order[1] == "ASC") {
      setOrder([orderBy, "DESC"]);
    } else {
      setOrder([orderBy, "ASC"]);
    }
  }
  // MARK: --- Hooks ---
  useEffect(() => {
    getHistory();
  }, []);

  useEffect(() => {
    getHistory();
  }, [page, step, searchText, selectedArea, dates, order]);

  // useEffect(() =>{console.log(order)}, [order]);
  return (
    <>
      <DataTable
        // className={cx("table")}
        cols={[20, 30, 15, 15, 20]}
        pointerOnHover
        highlightOnHover
        headItems={[
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => changeOrder("userId")}
          >
            <p>
              {t("NameOfUser")}
              {order[0] === "userId" && (
                <FontAwesomeIcon
                  style={{ marginLeft: "4px", fontSize: "15px" }}
                  icon={order[1] == "ASC" ? faCaretUp : faCaretDown}
                />
              )}
            </p>
          </div>,
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => changeOrder("URL")}
          >
            <p>
              {t("Actions")}
              {order[0] === "URL" && (
                <FontAwesomeIcon
                  style={{ marginLeft: "4px", fontSize: "15px" }}
                  icon={order[1] == "ASC" ? faCaretUp : faCaretDown}
                />
              )}
            </p>
          </div>,
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => changeOrder("IP")}
          >
            <p>
              IP
              {order[0] === "IP" && (
                <FontAwesomeIcon
                  style={{ marginLeft: "4px", fontSize: "15px" }}
                  icon={order[1] == "ASC" ? faCaretUp : faCaretDown}
                />
              )}
            </p>
          </div>,
          t("AreaApplication"),
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => changeOrder("createdAt")}
          >
            <p>
              {t("Time")}
              {order[0] === "createdAt" && (
                <FontAwesomeIcon
                  style={{ marginLeft: "4px", fontSize: "15px" }}
                  icon={order[1] == "ASC" ? faCaretUp : faCaretDown}
                />
              )}
            </p>
          </div>,
        ]}
        // row
        dataItems={logs?.map((item, index) => {
          return {
            data: [
              {
                comp: (
                  <div
                    key={item.id}
                    className="d-flex flex-row align-items-center"
                    style={{ minHeight: 19.5 }}
                  >
                    <p className="Bold_13 text-primary">
                      {item?.NguoiDung?.Ten}
                    </p>
                  </div>
                ),
              },
              {
                comp: (
                  <div key={item.id} className="position-relative">
                    <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                      {item?.URL}
                    </p>
                  </div>
                ),
              },
              {
                comp: (
                  <div key={item.id} className="Regular_13">
                    {item?.IP}
                  </div>
                ),
              },
              {
                comp: (
                  <div key={item.id} className="position-relative">
                    <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                      {item?.NguoiDung?.KhuVuc?.Ten}
                    </p>
                  </div>
                ),
              },
              {
                comp: (
                  <div key={item.id} className="position-relative">
                    <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y">
                      {dateFormat(item?.createdAt)}
                    </p>
                  </div>
                ),
              },
            ],
          };
        })}
        step={step}
        onRefresh={() => getHistory()}
        isLoading={isLoading}
      />

      <AppPagination
        className="bg-white"
        total={total}
        step={Global.gAvailablePagination}
        curStep={step}
        curPage={page}
        setPage={setPage}
        setStep={setStep}
      />
    </>
  );
}

export default TableListHistory;
