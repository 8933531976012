import { param } from "jquery";
import axiosClient from "./axiosClient";

const historyApi = {
  getLogs: (params) => {
    const url = "/users/logs";
    return axiosClient.get(url, { params });
  },
};

export default historyApi;
