import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap";
import "./style.scss";

FolderTree.propTypes = {
  data: PropTypes.array,
  setItem: PropTypes.func,
  currentItem: PropTypes.object,
};

FolderTree.defaultProps = {
  data: [],
  setItem: null,
  currentItem: {},
};

function FolderTree(props) {
  const { data, setItem, currentItem } = props;

  function handleChange(item, e) {
    if (setItem) {
      setItem(item, e);
    }
  }

  return (
    <div className="FolderTree">
      {data?.map((item, index) => {
        let showBorder = item?.MaKhuVuc !== 1;
        return (
          <div
            className={`accordion-items ${!showBorder ? "border-0" : ""}`}
            key={index}
          >
            <Accordion>
              <Accordion.Item
                className={`${item?.MaKhuVuc === 1 ? "border-0" : ""}`}
                eventKey=""
              >
                <div className="d-flex justify-content-between align-items-center p-4 border-top">
                  <div className="d-flex align-items-center">
                    <Accordion.Header className="m-0"></Accordion.Header>
                    <div
                      onClick={() => handleChange(item)}
                      className={`text-hover-primary ml-4 cursor-pointer `}
                    >
                      <span> {item?.Ten} </span>
                    </div>
                  </div>
                  <input
                    id={item?.MaKhuVuc}
                    className="cursor-pointer"
                    type="checkbox"
                    name={item?.MaKhuVuc}
                    value={item?.MaKhuVuc}
                    onChange={(e) => handleChange(item, e)}
                    checked={item?.MaKhuVuc === currentItem?.MaKhuVuc}
                  />
                </div>

                <Accordion.Body
                  className="p-0"
                  style={{
                    display: item?.childrens?.length > 0 ? "absolute" : "none",
                    marginLeft: "30px",
                  }}
                >
                  <div className="">
                    {
                      <FolderTree
                        currentItem={currentItem}
                        data={item?.childrens}
                        setItem={(item, e) => handleChange(item, e)}
                      />
                    }
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
}

export default FolderTree;
