import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Global from "general/Global";
import ToastHelper from "general/helpers/ToastHelper";
import Utils from "general/utils/Utils";
import useSort from "hooks/useSort";
import AppBreadcrumb from "components/AppBreadcrumb";
import CheckBox from "components/CheckBox";
import DataTable from "components/Table";
import AppPagination from "components/AppPagination";
import ModalUpdateStation from "../ModalUpdateStation";
import ModalDelete from "components/AppModal/ModalDelete";
import "./index.scss";
import {
  updateStations,
  thunkGetStationsByAreaId,
  thunkDeleteStation,
} from "pages/RadioStationManager/stationSlice";

function RadioStationTable(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // MARK: --- Params ---
  const {
    breadcrumbItems,
    currentArea,
    setCurrentStation,
    setCurrentAreaById,
    searchText,
    selectedItems,
    handleSetSelectedItems,
    handleSetFuncDeleteItems,
  } = props;

  const areasByUser = useSelector((state) => state.area.allAreas);
  const { stationByArea, isPending } = useSelector((state) => state.station);
  const [page, setPage] = useState(1);
  const [step, setStep] = useState(Global.gDefaultPagination);
  const [editableStation, setEditableStation] = useState();
  const [modalUpdateStationShowing, setModalUpdateStationShowing] =
    useState(false);
  const [modalDeleteShowing, setModalDeleteShowing] = useState(false);
  const { directionIndicator, sortOnClick } = useSort(
    stationByArea,
    (sortedArray) => dispatch(updateStations(sortedArray))
  );

  const filteredStations = useMemo(() => {
    return Utils.filter(stationByArea, "Ten", searchText);
  }, [searchText, stationByArea]);

  // MARK: --- Functions ---
  async function handleDeleteStation(id) {
    setModalDeleteShowing(true);
    setEditableStation(id);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    handleSetFuncDeleteItems(() => (id) => handleDeleteStation(id));
    return () => {
      handleSetSelectedItems([]);
    };
  }, []);

  useEffect(() => {
    if (currentArea) {
      dispatch(
        thunkGetStationsByAreaId({ MaKhuVuc: currentArea?.MaKhuVuc, limit: -1 })
      );
      setPage(1);
    }
  }, [currentArea]);

  return (
    <>
      <AppBreadcrumb
        items={breadcrumbItems}
        text={`${t("RadioStationsList")} (${stationByArea?.length} ${t(
          "Station"
        )})`}
      />
      <DataTable
        cols={[1, 20, 32, 32, 15]}
        headItems={[
          // Station ID
          <CheckBox
            checked={
              filteredStations?.length === selectedItems?.length &&
              filteredStations?.length !== 0
            }
            onCheck={() => handleSetSelectedItems(filteredStations)}
            onUncheck={() => handleSetSelectedItems([])}
          />,
          t("ID"),
          // Name
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("Ten")}
          >
            <p>
              {t("RadioStationName")}
              {directionIndicator("name")}
            </p>
          </div>,
          t("Area"),
          t("Manage"),
        ]}
        // row
        dataItems={filteredStations
          ?.slice(step * (page - 1), step * page)
          ?.map((item, index) => {
            let isRowCheck = !!selectedItems?.find(
              (station) => station.id === item.id
            );
            return {
              isRowCheck: isRowCheck,
              rowClick: () => {},
              data: [
                {
                  comp: (
                    <CheckBox
                      checked={isRowCheck}
                      onCheck={() =>
                        selectedItems?.find(
                          (station) => station.id === item.id
                        ) !== null &&
                        handleSetSelectedItems([...selectedItems, item])
                      }
                      onUncheck={() =>
                        handleSetSelectedItems(
                          selectedItems.filter(
                            (station) => station.id !== item.id
                          )
                        )
                      }
                    />
                  ),
                },
                {
                  comp: (
                    <div
                      className="d-flex flex-row align-items-center position-relative"
                      style={{ minWidth: 120 }}
                    >
                      <i className="fas fa-folder text-primary"></i>
                      <div
                        className="Bold_13 text-primary position-absolute w-100 top-50 translate-middle-y overflow-wrap pr-6"
                        style={{ left: 22 }}
                      >
                        {item.MaDaiTruyenThanh}
                      </div>
                    </div>
                  ),
                  onClick: () => {
                    setCurrentAreaById(item.MaKhuVuc, false);
                    setCurrentStation(item);
                  },
                },
                {
                  comp: (
                    <div
                      className="position-relative"
                      style={{ minWidth: 160 }}
                    >
                      <p className="Bold_13 text-primary position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                        {item.Ten}
                      </p>
                    </div>
                  ),
                  onClick: () => {
                    setCurrentAreaById(item.MaKhuVuc, false);
                    setCurrentStation(item);
                  },
                },
                {
                  comp: (
                    <div
                      className="position-relative"
                      style={{ minWidth: 120 }}
                    >
                      <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                        {item?.KhuVuc?.Ten}
                      </p>
                    </div>
                  ),
                  onClick: () => {
                    setCurrentAreaById(item.MaKhuVuc, false);
                    setCurrentStation(item);
                  },
                },
                {
                  comp: (
                    <div style={{ width: 80 }}>
                      <i
                        className="fa fa-cog cursor-pointer mr-5 text-hover-primary"
                        onClick={() => {
                          setEditableStation(item);
                          setModalUpdateStationShowing(true);
                        }}
                      />
                      <i
                        className="fa fa-trash-alt cursor-pointer mr-5 text-hover-danger"
                        onClick={() => {
                          setEditableStation(item);
                          setModalDeleteShowing(true);
                        }}
                      />
                      <i
                        className="fas fa-copy cursor-pointer text-hover-primary"
                        onClick={() => {
                          navigator.clipboard.writeText(item.Ten);
                          ToastHelper.showSuccess(t("Copied"));
                        }}
                      />
                    </div>
                  ),
                },
              ],
            };
          })}
        step={step}
        onRefresh={() => {
          if (currentArea) {
            dispatch(
              thunkGetStationsByAreaId({ MaKhuVuc: currentArea?.MaKhuVuc })
            );
            setPage(1);
          }
        }}
        isLoading={isPending}
      />

      <AppPagination
        className="bg-white"
        total={filteredStations?.length}
        step={Global.gAvailablePagination}
        curStep={step}
        curPage={page}
        setPage={setPage}
        setStep={setStep}
      />

      <ModalUpdateStation
        show={modalUpdateStationShowing}
        onClose={() => setModalUpdateStationShowing(false)}
        station={Array.isArray(editableStation) ? {} : editableStation}
      />

      <ModalDelete
        show={modalDeleteShowing}
        onClose={() => setModalDeleteShowing(false)}
        onSubmit={() => {
          dispatch(
            thunkDeleteStation({
              MaDaiTruyenThanh: Array.isArray(editableStation)
                ? editableStation
                : [editableStation?.MaDaiTruyenThanh],
            })
          );
          handleSetSelectedItems([]);
        }}
        title={t("DeleteStation")}
        text={
          Array.isArray(editableStation)
            ? `Bạn có chắc muốn xóa ${editableStation?.length} đài truyền thanh không?`
            : `Bạn có chắc muốn xóa đài truyền thanh ${editableStation?.name} không?`
        }
      />
    </>
  );
}

export default RadioStationTable;
