import axiosClient from "./axiosClient";

const ipRadioApi = {
  getDevicesInArea: (params) => {
    const url = `/devices/ipradio`;
    return axiosClient.get(url, { params });
  },
};

export default ipRadioApi;
