import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { AppIcons } from "general/constants/AppResource";
import OTPInput from "pages/Login/components/OTPInput";
import { current, unwrapResult } from "@reduxjs/toolkit";
import {
  thunkGetCurrentUserInfo,
  thunkVerifyToken,
} from "pages/Login/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PreferenceKeys from "general/constants/PreferenceKeys";
import Global from "general/Global";
import ToastHelper from "general/helpers/ToastHelper";

TwoFactorAuthScreen.propTypes = {};

function TwoFactorAuthScreen(props) {
  const otpRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const timer = process.env.REACT_APP_OTP_TIMEOUT;
  const [count, setCount] = React.useState(timer / 1000);
  localStorage.removeItem(PreferenceKeys.twofaAccessToken);

  useEffect(() => {
    if (timer == 0) {
      // Thoi gian nhap OTP thoai mai
    } else {
      const otpTimeCountdown = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);

      if (count === 0) {
        clearInterval(otpTimeCountdown);
        history.replace("sign-in");
        ToastHelper.showError("Hết thời gian nhập OTP");
      }
      return () => {
        clearInterval(otpTimeCountdown);
      };
    }
  }, [count]);

  useEffect(() => {
    document.getElementsByClassName("otp-input")[0].blur();
  }, []);

  const onSubmit = async (values, OTP) => {
    try {
      setLoading(true);
      const res = unwrapResult(
        await dispatch(thunkVerifyToken({ Token: OTP }))
      );
      const result = res?.data?.MoTa;
      setLoading(false);
      if (result === "ThanhCong") {
        // Otp thanh cong
        localStorage.setItem(
          PreferenceKeys.refreshToken,
          Global.initialRefreshToken
        );
        Global.refreshToken = Global.initialRefreshToken;
        // debugger
        history.replace("/dashboard");
      } else {
        history.replace("/auth/sign-in");
        // otpRef.current.resetOTP();
        // setOtp("");
        // document
        //   .getElementsByClassName("otp-input")[0]
        //   .getElementsByTagName("div")[0]
        //   .getElementsByTagName("div")[0]
        //   .getElementsByTagName("input")[0]
        //   .focus();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCancel = () => {
    history.replace("sign-in");
  };

  const keyDownHandler = (event) => {
    if (otp.length == 6) {
      if (event.key === "Enter") {
        event.preventDefault();
        onSubmit();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [otp]);

  return (
    <div className="login-page">
      <div
        className="container h-100 d-flex flex-column"
        style={{ minHeight: "100vh" }}
      >
        <header style={{}}>
          <div className="login-header-wrapper">
            <div className="logo">
              <img src={AppIcons.icLogoLogin} alt="logo" />
            </div>
            <div className="login-title">{t("loginTitle")}</div>
          </div>
        </header>
        <main style={{}}>
          <div className="login-form ">
            {/* <LoginForm redirectLogin={props.location?.state?.from} /> */}
            <div className="text-center">
              <p
                className="font-size-h3 font-weight-bold"
                style={{ color: "#3F434A" }}
              >
                {/* {t("twoFactorAuthentication")} */}
                Xác thực hai lớp
              </p>
              <p
                className="line-height-md mt-1 mb-0"
                style={{ color: "#8A9099" }}
              >
                {t("twoFactorAuthenticationDescription")}
              </p>
            </div>
            <div className="justify-content-center otp-input">
              <OTPInput
                func={(otp) => setOtp(otp)}
                ref={otpRef}
                onSubmit={onSubmit}
              />
            </div>
            <button
              className="login-button mb-4"
              disabled={otp.length != 6}
              // onClick={() => {
              //   onSubmit();
              // }}
            >
              {loading === false ? null : (
                <span className="spinner-border spinner-border-sm mr-2 mt-1"></span>
              )}
              <span className="text-white ">{t("login")}</span>
            </button>
            <button
              className="cancel-button"
              onClick={() => {
                onCancel();
              }}
            >
              {t("Cancel")}
            </button>
            {timer == 0 ? null : (
              <p className="text-center font-weight-bold mb-0">
                Thời gian nhập OTP còn lại: {count}s
              </p>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default TwoFactorAuthScreen;
