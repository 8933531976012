import Utils from "general/utils/Utils";
import axiosClient from "./axiosClient";

const authApi = {
  // sign in
  signIn: (params) => {
    const url = "/oauth/login";
    return axiosClient.post(url, params);
  },

  // 2fa verifyToken
  verifyToken: (params) => {
    const url = "/oauth/verifyToken";
    return axiosClient.post(url, params);
  },

  // verify OTP enable 2 factor
  verifyOTP: (params) => {
    const url = "/oauth/verifyOTP";
    return axiosClient.post(url, params);
  },

  // enableTwoFactor
  enableTwoFactor: (params) => {
    const url = "/oauth/enableTwoFactor";
    return axiosClient.post(url, params);
  },

  // get current account info
  getCurrentUserInfo: () => {
    const url = "/oauth/me";
    return axiosClient.post(url);
  },

  //update currentuser info
  updateCurrentUserInfo: (params) => {
    const url = `/users/edit`;

    let formData = new FormData();

    _.entries(params).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return axiosClient.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  //changePassword
  updateCurrentUserPassword: async (params) => {
    const url = `/oauth/change-password`;
    let newParams = {};
    if (params.OTP) newParams.OTP = params.OTP;
    newParams.NewPassword = await Utils.hash(params.newPassword);
    newParams.OldPassword = await Utils.hash(params.oldPassword);
    let formData = new FormData();

    _.entries(newParams).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return axiosClient.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  getOTP: () => {
    const url = "/auth/getOTP";
    return axiosClient.get(url);
  },

  // verifyOTP: async (params) => {
  //   let formData = new FormData();
  //   const url = "/auth/verifyOTP";
  //   _.entries(params).forEach(([key, value]) => {
  //     formData.append(key, value);
  //   });

  //   return axiosClient.post(url, formData, {
  //     headers: { "Content-Type": "multipart/form-data" },
  //   });
  // },

  //get link to reset password
  getResetPasswordLink: async (params) => {
    let formData = new FormData();
    const url = "/oauth/reset-password";
    _.entries(params).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return axiosClient.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  // api reset password
  resetPassword: async (params) => {
    let newPassword = await Utils.hash(params.newPassword);
    let resetToken = params.resetToken;
    const url = `/oauth/reset-password?resetToken=${resetToken}&newPassword=${newPassword}`;
    return axiosClient.get(url);
  },

  getNotifications: () => {
    const url = `/notification/`;

    return axiosClient.get(url);
  },
};

export default authApi;
