import React, { Component } from "react";
import classes from "./SelectArea.module.scss";
import PropTypes from "prop-types";

class SelectAreaItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: this.props.area?.state?.opened || false,
    };

    this.toggleChild = this.toggleChild.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  toggleChild() {
    this.props.setDropdownHeight();
    this.setState({
      expand: !this.state.expand,
    });
  }

  onSelect(area) {
    if ((area && Number(area)) || typeof area === "string") {
      this.props.onSelect(area);
    } else {
      this.props.onSelect(area);
    }
  }

  // onSelect(area) {
  //   if (area && Number(area)) {
  //     this.props.onSelect(area);
  //   } else {
  //     this.props.onSelect(area);
  //   }
  // }

  // static getDerivedStateFromProps(props, state) {
  //     debugger;
  //     if (props.area?.state?.opened !== state.expand) {
  //         return {
  //             expand: props.area?.state?.opened || false,
  //         }
  //     }
  //     return null;
  // }

  render() {
    let childArea = Array.isArray(this.props.area?.childrens)
      ? this.props.area?.childrens
      : [];

    return (
      <div className={`${classes.tree_item_container}`}>
        <div className={classes.tree_item}>
          {childArea.length ? (
            this.state.expand ? (
              <i
                className={classes.toggle_expand + " fal fa-minus-square mr-2"}
                onClick={this.toggleChild}
              />
            ) : (
              <i
                className={classes.toggle_expand + " fal fa-plus-square mr-2"}
                onClick={this.toggleChild}
              />
            )
          ) : (
            <i className="fal fa-plus-square mr-2 invisible" />
          )}
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() => this.onSelect(this.props.area)}
          >
            <i className="fal fa-folder-open text-primary mr-2" />
            <div className="flex-grow-1">{this.props.area?.Ten}</div>
          </div>
        </div>
        <div className={this.state.expand ? "" : "d-none"}>
          {childArea.length && this.state.expand
            ? childArea.map((child, index) => {
                return (
                  <SelectAreaItem
                    area={child}
                    key={index}
                    onSelect={this.onSelect}
                    exceptIds={this.props.exceptIds}
                    setDropdownHeight={this.props.setDropdownHeight}
                  />
                );
              })
            : ""}
        </div>
      </div>
    );
  }
}

SelectAreaItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  setDropdownHeight: PropTypes.func.isRequired,
  area: PropTypes.object,
  exceptIds: PropTypes.array,
};

SelectAreaItem.defaultProps = {
  exceptIds: [],
};

export default SelectAreaItem;
