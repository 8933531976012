import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import Footer from "components/Footer";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PreferenceKeys from "general/constants/PreferenceKeys";
import "./styles.scss";
import { useSelector } from "react-redux";
import Utils from "general/utils/Utils";
import { AppImages } from "general/constants/AppResource";
import "./styles.scss";
import ModalChangePassword from "pages/AccountManager/components/ModalChangePassword";

AccountDropdown.propTypes = {
  moveRight: PropTypes.bool,
};

AccountDropdown.defaultProps = {
  moveRight: true,

  moveRight: PropTypes.bool,
};

AccountDropdown.defaultProps = {
  moveRight: true,
};

function AccountDropdown(props) {
  const [modalChangePassword, setModalChangePassword] = useState(false);
  const currentUser = useSelector((state) => state.auth.current);
  const avatarLink = Utils.isObjectEmpty(currentUser?.AnhDaiDien)
    ? AppImages.imgDefaultAvatar.default
    : Utils.getFullUrl(currentUser?.AnhDaiDien);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const refDropdown = useRef(null);

  const level = ["Tỉnh", "Huyện", "Xã"];

  function handleSignOut() {
    localStorage.removeItem(PreferenceKeys.accessToken);
    localStorage.removeItem(PreferenceKeys.refreshToken);
    history.push("/auth/sign-in");
  }

  function handleAccountOverview() {
    history.push("/account/account-overview");
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        show &&
        refDropdown.current &&
        !refDropdown.current.contains(event.target)
      ) {
        setShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  const handleScroll = (event) => {
    let scrollY = window.scrollY;
    if (scrollY > 50) {
      setShow(false);
    } else {
    }
  };

  const changeDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  return (
    <Dropdown style={{ zIndex: "15" }} show={show}>
      <Dropdown.Toggle
        ref={refDropdown}
        onMouseDown={changeDropdown}
        className=" d-flex justify-content-center align-items-center bg-transparent p-0 border-0 position-static "
      >
        {props.children}
      </Dropdown.Toggle>

      <Dropdown.Menu
        rootCloseEvent="click"
        show={show}
        // onMouseLeave={hideDropdown}
        className={`p-0 mt-4  ${
          props.moveRight ? "accountDropdownMenu" : null
        }`}
        style={{
          minWidth: "220px",
          maxWidth: "240px",
          boxShadow: "0px 10px 60px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Dropdown.Header className="w-100 border-bottom rounded-top">
          <div className="d-flex justify-content-start align-items-center my-1 w-100">
            <div className="" style={{ width: "50px", height: "50px" }}>
              <img
                src={avatarLink}
                className="rounded"
                style={{ width: "50px", height: "50px", objectFit: "cover" }}
                onError={(e) => {
                  e.target.src = AppImages.imgDefaultAvatar.default;
                }}
              ></img>
            </div>
            <div className=" d-flex flex-column ml-3">
              <div className="" style={{ width: "" }}>
                <p
                  className="font-weight-boldest line-height-lg text-uppercase "
                  style={{
                    color: "#4A5677",
                    fontSize: "14px",
                    lineHeight: "17px",
                    whiteSpace: "normal",
                  }}
                >
                  {currentUser?.Ten}
                </p>
              </div>

              <span
                className="font-weight-bolder  line-height-lg "
                style={{
                  color: "#5180FB",
                  fontSize: "13px",
                  // ,backgroundColor:"rgba(81, 128, 251, 0.1)"
                }}
              >
                {t("Level")} : {level[currentUser?.Cap]}
              </span>
            </div>
          </div>
        </Dropdown.Header>
        <Dropdown.Item
          onMouseDown={handleAccountOverview}
          className="d-flex align-items-center border-bottom"
          style={{ height: "48px" }}
        >
          <i
            className="fas fa-user-cog mb-1"
            style={{ color: "#4A5677", width: "17px", height: "13px" }}
          ></i>
          <span
            className="font-weight-bolder ml-4"
            style={{ color: "#4A5677", fontSize: "13px" }}
          >
            {t("AccountOverview")}
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          className="d-flex align-items-center border-bottom"
          style={{ height: "48px" }}
          onMouseDown={() => {
            setModalChangePassword(true);
          }}
        >
          <i
            className="fas fa-lock-alt mb-1"
            style={{ color: "#4A5677", width: "17px", height: "13px" }}
          ></i>
          <span
            className="font-weight-bolder ml-4"
            style={{ color: "#4A5677", fontSize: "13px" }}
          >
            {t("ChangePassword")}
          </span>
        </Dropdown.Item>
        <Dropdown.Item
          onMouseDown={() => {
            handleSignOut();
          }}
          className="d-flex align-items-center"
          style={{ height: "48px" }}
        >
          <i
            className="fas fa-sign-out mb-1"
            style={{ color: "#4A5677", width: "17px", height: "13px" }}
          ></i>
          <span
            className="font-weight-bolder ml-4"
            style={{ color: "#4A5677", fontSize: "13px" }}
          >
            {t("SignOut")}
          </span>
        </Dropdown.Item>
      </Dropdown.Menu>
      <ModalChangePassword
        show={modalChangePassword}
        onClose={() => setModalChangePassword(false)}
      />
    </Dropdown>
  );
}

export default AccountDropdown;
