import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { Modal } from "react-bootstrap";

ModalDeletedIndicator.propTypes = {
  items: PropTypes.array,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  deletedItemCount: PropTypes.number,
};

ModalDeletedIndicator.defaultProps = {
  items: [],
  show: false,
  onClose: null,
  title: "",
  deletedItemCount: 0,
};

function ModalDeletedIndicator(props) {
  // MARK: --- Params ---
  const { items, show, onClose, title, deletedItemCount } = props;
  const [filled, setFilled] = useState(deletedItemCount);

  // MARK: --- Function ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    setFilled(
      Math.floor(parseInt(deletedItemCount) / parseInt(items.length)) * 100
    );
  }, [items, deletedItemCount]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center align-itemCount-center">
          <div className="progressbar">
            <div
              style={{
                height: "100%",
                width: `${filled}%`,
                backgroundColor: "#3699FF",
                transition: "width 0.5s",
              }}
            ></div>
            <span className="progressPercent">{filled}%</span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalDeletedIndicator;
