import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { TokenService } from "../../services/storage.service";
import "./LoginForm.scss";
import { useHistory } from "react-router-dom";
import FormikControl from "../inputs/FormikControl";
import { useTranslation } from "react-i18next";
import { thunkSignIn } from "../../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import PreferenceKeys from "general/constants/PreferenceKeys";
import Utils from "general/utils/Utils";
import ModalResetPassword from "./components/ModalResetPassword";
import ReCAPTCHA from "react-google-recaptcha";
import Global from "general/Global";

const LoginForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errorMsg = useSelector((state) => state?.error?.msg);
  const [errMsgAPI, setErrMsgAPI] = useState(null);
  const [isHiddenPassword, setIsHiddenPassword] = useState(true);
  const [modalResetPassword, setModalResetPassword] = useState(false);
  let needCaptcha = process.env.REACT_APP_CAPTCHA;
  const [loading, setLoading] = useState(false);

  // check verify captcha chưa , needCaptcha là false thì check là true
  const [captchaCheck, setCaptchaCheck] = useState(
    needCaptcha != "true" ? true : false
  );

  // ẩn hiện captcha
  const [captchaVisibility, setCaptchaVisibility] = useState(
    localStorage.getItem(PreferenceKeys.captchaLocalStorage) == "true"
      ? true
      : false
  );
  const captcha = useRef();

  const passwordMaxLength = process.env.REACT_APP_PASSWORD_MAX_LENGTH;
  const passwordMinLength = process.env.REACT_APP_PASSWORD_MIN_LENGTH;
  const passwordHaveCapitalLetter =
    process.env.REACT_APP_PASSWORD_CAPITAL_LETTER;
  const passwordHaveNonCapitalLetter =
    process.env.REACT_APP_PASSWORD_NON_CAPITAL_LETTER;
  const passwordHaveNumber = process.env.REACT_APP_PASSWORD_NUMBER_LETTER;
  const [passwordRequirement, setPasswordRequirement] = useState(null);
  let initialPasswordErrorText =
    "Mật khẩu gồm ít nhất " + passwordMinLength + " kí tự";
  const [passwordRequirementErrorText, setPasswordRequirementErrorText] =
    useState(initialPasswordErrorText);
  let regExp = new RegExp(/^/);

  useEffect(() => {
    document.getElementsByName("username")[0].focus();
  }, []);

  useEffect(() => {
    if (passwordHaveCapitalLetter == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[A-Z])/.source);
      initialPasswordErrorText =
        initialPasswordErrorText + ", 1 kí tự viết hoa";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if (passwordHaveNonCapitalLetter == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[a-z])/.source);
      initialPasswordErrorText =
        initialPasswordErrorText + ", 1 kí tự viết thường";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if (passwordHaveNumber == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[0-9])/.source);
      initialPasswordErrorText = initialPasswordErrorText + ", 1 chữ số";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    // regExp = new RegExp(regExp.source + /[^\s]/.source);
    setPasswordRequirement(regExp);
  }, []);

  const initialValues = {
    username: "",
    password: "",
    autoLogin: false,
  };

  const validationSchema = Yup.object({
    username: Yup.string().required(t("enterAccount")),
    password: Yup.string()
      // .min(passwordMinLength, passwordRequirementErrorText)
      // .max(passwordMaxLength, passwordRequirementErrorText)
      .required(t("enterPass")),
    // .matches(passwordRequirement, passwordRequirementErrorText)
    // .matches(/^(?!.*[\s])/, "Có khoảng trắng trong mật khẩu"),
  });

  const onSubmit = async (values, props) => {
    console.log("login", values);
    const hPassword = await Utils.hash(values.password);
    setLoading(true);
    // setDisableButton(true);
    try {
      const res = unwrapResult(
        await dispatch(
          thunkSignIn({
            Username: values.username,
            Password: hPassword,
            autoLogin: values.autoLogin,
            captcha: captchaCheck ? Global.captchaToken : "",
          })
        )
      );

      // debugger
      if (res) {
        // setDisableButton(false);
        setLoading(false);
        const userData = res?.data?.NoiDung;

        // Dang nhap thanh cong
        if (res?.data?.MoTa === "ThanhCong") {
          localStorage.removeItem(PreferenceKeys.captchaLocalStorage);
          if (userData?.TwoFactor === 1) {
            localStorage.setItem(PreferenceKeys.twofaAccessToken, "approved");

            // neu tai khoan co xac thuc 2 lop thi luu 1 token de vao trang xac thuc 2 lop
            history.replace("/auth/2fa-verify");
          } else {
            history.replace("/dashboard");
          }
        } else {
          const data = res?.response?.data;

          if (data?.NoiDung) {
            if (data?.NoiDung?.failedLogin) {
              if (needCaptcha) {
                setCaptchaCheck(false);
                setCaptchaVisibility(true);
                console.log(captcha);
                localStorage.setItem(
                  PreferenceKeys.captchaLocalStorage,
                  "true"
                );
              }
              setErrMsgAPI(
                "Số lần đăng nhập còn lại :".concat(data?.NoiDung?.failedLogin)
              );
            } else {
              setErrMsgAPI(data?.MoTa);
            }
            captcha.current.reset();
          }
        }
      }
    } catch (error) {
      props.setSubmitting(false);
      console.log(error);
      // setDisableButton(false);
      setLoading(false);
    }
  };

  function onChangeCaptcha(value) {
    // console.log("Captcha value:", value);
    Global.captchaToken = value;
    console.log(value);
    setCaptchaCheck(true);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="form-title">{t("login")}</div>
              {errorMsg ? (
                <div className="login-form-error m-0">
                  {errorMsg.Description}
                </div>
              ) : null}
              <FormikControl
                control="input"
                type="text"
                label={
                  <p className="font-weight-normal font-size-sm line-height-sm mb-0">
                    {" "}
                    {t("userName")}{" "}
                  </p>
                }
                placeholder={t("userNamePlaceHolder")}
                name="username"
              />
              {errMsgAPI === null ? null : (
                <p style={{ color: "red" }}>{errMsgAPI}</p>
              )}
              <FormikControl
                control="input"
                type={isHiddenPassword ? "password" : "text"}
                label={
                  <p className="font-weight-normal font-size-sm line-height-sm mb-0">
                    {t("password")}
                  </p>
                }
                placeholder={!isHiddenPassword ? "" : t("passwordPlaceHolder")}
                name="password"
                setIsHiddenPassword={setIsHiddenPassword}
                isHiddenPassword={isHiddenPassword}
              />
              <div className="login-form-check">
                {/* <FormikControl
                  control="oneCheckbox"
                  label={t("autoLogin")}
                  name="autoLogin"
                /> */}
                <a
                  onMouseDown={() => {
                    history.push("/auth/forgot-password");
                  }}
                  href=""
                >
                  {t("forgotPassword")}
                </a>
              </div>

              {needCaptcha ? (
                <div className={`d-flex justify-content-center my-3`}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_APIKEY}
                    onChange={onChangeCaptcha}
                    ref={captcha}
                  />
                </div>
              ) : null}

              <button
                className="login-button d-flex justify-content-center"
                type="submit button"
                disabled={!formik.isValid || loading || !captchaCheck}
              >
                {loading === false ? null : (
                  <span className="spinner-border spinner-border-sm mr-2 mt-1"></span>
                )}
                <p className="font-weight-bold text-white mb-0">{t("login")}</p>
              </button>
            </Form>
          );
        }}
      </Formik>

      <ModalResetPassword
        show={modalResetPassword}
        onClose={() => setModalResetPassword(false)}
      />
    </>
  );
};

export default LoginForm;
