import AppResource from "general/constants/AppResource";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./style.scss";
import FolderTree from "../FolderTree";
import Empty from "components/Empty";

TreeItemDropdown.propTypes = {
  tree: PropTypes.array,
  onChange: PropTypes.func,
  currentItem: PropTypes.object,
  additionalToggleClassname: PropTypes.string,
  initialToggleText: PropTypes.string,
};

TreeItemDropdown.defautProps = {
  tree: [],
  onChange: null,
  currentItem: {},
  additionalToggleClassname: "",
  initialToggleText: "Tất cả",
};

function TreeItemDropdown(props) {
  // MARK --- Params ---
  const {
    tree,
    onChange,
    currentItem,
    additionalToggleClassname,
    initialToggleText,
  } = props;
  const { t } = useTranslation();

  // MARK --- Function ---
  function handleChange(item) {
    if (onChange) {
      onChange(item);
    }
  }

  return (
    <Dropdown className="TreeItemDropdown">
      <Dropdown.Toggle
        className={`border shadow-none ${additionalToggleClassname}`}
        variant=""
        id="dropdown-basic"
      >
        {currentItem?.Ten ?? initialToggleText}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="min-w-375px">
          {tree?.length > 0 ? (
            <FolderTree
              currentItem={currentItem}
              data={tree}
              setItem={(item) => {
                handleChange(item);
              }}
            />
          ) : (
            <div className="pt-12">
              <Empty
              // text={t("NoData")}
              // visible={false}
              // imageEmpty={AppResource.icons.icEmptyBox}
              // imageEmptyPercentWidth={80}
              />
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TreeItemDropdown;
