import { param } from "jquery";
import axiosClient from "./axiosClient";
import _ from "lodash";

const stationApi = {
  getStations: (params) => {
    const url = `/stations`;
    return axiosClient.get(url, { params });
  },
  getStationsByAreaId: (areaId) => {
    const url = `/stations/area/${areaId}`;
    return axiosClient.get(url);
  },
  getStationInfo: (stationId) => {
    const url = `/stations/${stationId}`;
    return axiosClient.get(url);
  },
  getStationDevices: (stationId, params) => {
    const url = `/stations/${stationId}/devices`;
    return axiosClient.get(url, { params });
  },

  getIpRadioStationDevices: (params) => {
    const url = `/stations/devices`;
    return axiosClient.get(url, { params });
  },

  updateStation: (params) => {
    const { id, name } = params;
    const url = `/stations/update`;
    return axiosClient.put(url, params);
  },

  createStation: (params) => {
    const { areaId, name } = params;
    const url = `/stations/create`;
    return axiosClient.post(url, params);
  },

  deleteStation: (MaDaiTruyenThanh) => {
    const url = `/stations/delete`;
    return axiosClient.delete(url, {
      data: { MaDaiTruyenThanh: JSON.stringify(MaDaiTruyenThanh) },
    });
  },

  //sondang
  getStationsByMaKhuVuc: (MaKhuVuc) => {
    const url = `/stations?MaKhuVuc=${MaKhuVuc}`;
    return axiosClient.get(url);
  },
};

export default stationApi;
