import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import areaApi from "api/areaApi";
import Utils, { sortArrayIntoTree } from "general/utils/Utils";

export const thunkGetAreas = createAsyncThunk(
  "area/all",
  async (params, thunkAPI) => {
    const res = await areaApi.getAreas();
    return res;
  }
);

export const thunkGetDevicesInArea = createAsyncThunk(
  "devices_in_area",
  async (params) => {
    const { areaId, page, limit, q, orderField, orderMode } = params;
    const res = await areaApi.getDevicesInArea(areaId, {
      page,
      limit,
      q,
      orderField,
      orderMode,
    });
    return res;
  }
);

const areaSlice = createSlice({
  name: "area",
  initialState: {
    isPending: false,
    allAreas: [],
    areas: [],
    currentArea: null,
    devicesInArea: [],
    listArea: [],
  },
  reducers: {
    setAllAreas: (state, action) => {
      state.allAreas = action.payload;
    },
    setAreas: (state, action) => {
      state.areas = action.payload;
    },
    setCurrentArea: (state, action) => {
      state.currentArea = action.payload;
    },
    addArea: (state, action) => {
      state.allAreas = [...state.allAreas, action.payload];
      if (state.currentArea.MaKhuVuc === action.payload.MaKhuVucCha)
        state.areas = [...state.areas, action.payload];
    },
    updateArea: (state, action) => {
      for (let i = 0; i < state.allAreas.length; i++)
        if (state.allAreas[i].id === action.payload.id) {
          state.allAreas[i].Ten = action.payload.Ten;
          break;
        }

      for (let i = 0; i < state.areas.length; i++)
        if (state.areas[i].id === action.payload.id) {
          state.areas[i].Ten = action.payload.Ten;
          break;
        }
    },
    deleteArea: (state, action) => {
      if (!Array.isArray(action.payload)) {
        state.allAreas = state.allAreas.filter(
          (item) => item.id !== action.payload
        );
        state.areas = state.areas.filter((item) => item.id !== action.payload);
      } else
        for (let provider of action.payload) {
          state.allAreas = state.allAreas.filter(
            (item) => item.id !== provider
          );
          state.areas = state.areas.filter((item) => item.id !== provider);
        }
    },
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
  },
  extraReducers: {
    [thunkGetAreas.pending]: (state, action) => {
      state.isPending = true;
    },
    [thunkGetAreas.rejected]: (state, action) => {
      state.isPending = false;
    },
    [thunkGetAreas.fulfilled]: (state, action) => {
      state.isPending = false;
      const { MoTa, NoiDung } = action.payload.data;
      if (MoTa == "ThanhCong") {
        state.allAreas = sortArrayIntoTree(NoiDung.DanhSachKhuVuc);
        state.listArea = NoiDung.DanhSachKhuVuc;
      }
    },

    [thunkGetDevicesInArea.pending]: (state, action) => {
      state.isPending = true;
    },
    [thunkGetDevicesInArea.rejected]: (state, action) => {
      state.isPending = false;
    },
    [thunkGetDevicesInArea.fulfilled]: (state, action) => {
      state.isPending = false;
      const { result, devices } = action.payload.data;
      if (result == "success") {
        state.devicesInArea = devices
          .map((item) => {
            if (
              !Utils.isObjectEmpty(item.latitude) &&
              !Utils.isObjectEmpty(item.longitude) &&
              item.latitude >= -90 &&
              item.latitude <= 90 &&
              item.longitude >= -180 &&
              item.longitude <= 180
            )
              return item;
          })
          .filter((item) => !Utils.isObjectEmpty(item));
      }
    },
  },
});

const { actions, reducer } = areaSlice;

export const {
  setAllAreas,
  setAreas,
  setCurrentArea,
  addArea,
  updateArea,
  deleteArea,
} = actions;
export default reducer;
