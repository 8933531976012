import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import "./style.scss";
import providerApi from "api/providerApi";
import {
  addProvider,
  updateProvider,
} from "pages/ProviderManager/providerSlice";
import ToastHelper from "general/helpers/ToastHelper";
import BaseTextField from "general/custom-fields/BaseTextField";

ModalCreateProvider.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  provider: PropTypes.object,
};

ModalCreateProvider.defaultProps = {
  show: false,
  onClose: null,
  provider: null,
};

function ModalCreateProvider(props) {
  // MARK: --- Params ---
  const { show, onClose, provider } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      MaNhaCungCap: "",
      Email: "",
      Ten: "",
      LienHe: "",
      DiaChi: "",
    },
    onSubmit: (values) => {
      if (provider)
        handleModifyProvider({
          id: provider.id,
          MaNhaCungCap: values.MaNhaCungCap,
          Ten: values.Ten,
          Email: values.Email,
          LienHe: values.LienHe,
          DiaChi: values.DiaChi,
        });
      else
        handleAddProvider({
          MaNhaCungCap: values.MaNhaCungCap,
          Ten: values.Ten,
          Email: values.Email,
          LienHe: values.LienHe,
          DiaChi: values.DiaChi,
        });
      handleClose();
    },
    validationSchema: yup.object({
      Ten: yup.string().required(t("Required")),
    }),
  });

  useEffect(() => {
    if (provider) {
      formik.getFieldHelpers("Ten").setValue(provider?.Ten);
      formik.getFieldHelpers("Email").setValue(provider?.Email);
      formik.getFieldHelpers("DiaChi").setValue(provider?.DiaChi);
      formik.getFieldHelpers("LienHe").setValue(provider?.LienHe);
      formik.getFieldHelpers("DiaChi").setValue(provider?.DiaChi);
    }
  }, [provider]);

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  async function handleAddProvider(params) {
    const res = await providerApi.addProvider(params);
    const { MoTa, NoiDung } = res.data;

    if (MoTa == "ThanhCong") {
      dispatch(addProvider(NoiDung.NhaCungCap));
      ToastHelper.showSuccess(t("CreatedProviderSuccessfully"));
    }
  }

  async function handleModifyProvider(params) {
    const res = await providerApi.modifyProvider(params);
    const { MoTa } = res.data;

    if (MoTa == "ThanhCong") {
      dispatch(updateProvider(params));
      ToastHelper.showSuccess(t("UpdatedProviderSuccessfully"));
    }
  }

  return (
    <div>
      <Modal
        className="font-weight-bold"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => {
          handleClose();
          formik.handleReset();
        }}
      >
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>
            <p className="Bold_13">{t("Provider")}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="MaNhaCungCap"
            label={t("ProviderCode")}
            placeholder={t("ProviderCode")}
            fieldProps={formik.getFieldProps("MaNhaCungCap")}
            fieldHelpers={formik.getFieldHelpers("MaNhaCungCap")}
            fieldMeta={formik.getFieldMeta("MaNhaCungCap")}
          />

          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="Ten"
            label={t("ProviderName")}
            placeholder={t("ProviderName")}
            fieldProps={formik.getFieldProps("Ten")}
            fieldHelpers={formik.getFieldHelpers("Ten")}
            fieldMeta={formik.getFieldMeta("Ten")}
          />

          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="Email"
            label={t("Email")}
            placeholder={t("Email")}
            fieldProps={formik.getFieldProps("Email")}
            fieldHelpers={formik.getFieldHelpers("Email")}
            fieldMeta={formik.getFieldMeta("Email")}
          />

          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="LienHe"
            label={t("Contact")}
            placeholder={t("Contact")}
            fieldProps={formik.getFieldProps("LienHe")}
            fieldHelpers={formik.getFieldHelpers("LienHe")}
            fieldMeta={formik.getFieldMeta("LienHe")}
          />

          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="Address"
            label={t("Address")}
            placeholder={t("DiaChi")}
            fieldProps={formik.getFieldProps("DiaChi")}
            fieldHelpers={formik.getFieldHelpers("DiaChi")}
            fieldMeta={formik.getFieldMeta("DiaChi")}
          />

          <BaseTextField
            className="FieldName Normal_12"
            name="APIKey"
            label={t("API Key")}
            placeholder={t("API Key")}
            fieldProps={formik.getFieldProps("APIKey")}
            fieldHelpers={formik.getFieldHelpers("APIKey")}
            fieldMeta={formik.getFieldMeta("APIKey")}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div className="row w-100">
            <div className="col-6">
              <a
                className="w-100 btn btn-light  font-weight-bold"
                onClick={() => handleClose()}
              >
                {t("Cancel")}
              </a>
            </div>

            <div className="col-6">
              <a
                className="w-100 btn btn-primary font-weight-bold"
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                {t("Save")}
              </a>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalCreateProvider;
