import React, { useEffect } from "react";
import "./SpeakerInfo.scss";
import State from "components/State";
import Connection from "components/Connection";
import { useTranslation } from "react-i18next";

const SpeakerInfo = (props) => {
  // MARK: --- Params ---
  const { speakers } = props;
  const { t } = useTranslation();

  // MARK: --- Functions ---

  // MARK: --- Hooks ---

  return (
    <div className="spk_info_container">
      <div className="d-flex justify-content-between pr-2">
        <span className="spk_info_title">Thiết Bị</span>
        <i
          id="infoBoxCloseButton"
          className="far fa-times cursor-pointer"
          style={{ width: 20, height: 20 }}
          onClick={props.onClose}
        />
      </div>
      <hr style={{ marginBottom: "0px" }} />
      <div
        className="d-flex flex-row font-weight-bold"
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
          backgroundColor: "#F8F8F8",
          height: "46px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "60px", marginRight: 5 }}>ID</div>
        <div style={{ width: "100px" }}>Tên Thiết Bị</div>
        <div style={{ width: "110px" }}>Trạng Thái</div>
        <div style={{ width: "100px" }}>Kết Nối</div>
        <div style={{ width: "55px" }}>Thiết lập</div>
        <div style={speakers.length > 3 ? { width: "18px" } : null} />
      </div>
      <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
      <div
        style={
          speakers.length > 3 ? { height: "140px", overflow: "auto" } : null
        }
      >
        {speakers.map((item) => {
          return (
            <div
              key={item.deviceId}
              id={`DeviceId${item.deviceId}`}
              className="d-flex flex-row bg-hover-light"
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                height: "46px",
                alignItems: "center",
              }}
            >
              <p
                id={`DeviceId${item.deviceId}`}
                className="text-primary font-weight-bold"
                style={{
                  width: "60px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  direction: "rtl",
                  textAlign: "left",
                  marginRight: 5,
                  paddingLeft: "-10px",
                  cursor: "pointer",
                }}
              >
                {item.deviceId}
              </p>
              <div
                id={`DeviceId${item.deviceId}`}
                style={{
                  width: "100px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                className="font-weight-bold"
              >
                {item.name}
              </div>

              <div id={`DeviceId${item.deviceId}`} style={{ width: "110px" }}>
                <State id={`DeviceId${item.deviceId}`} state={item.state} />
              </div>
              <div id={`DeviceId${item.deviceId}`} style={{ width: "100px" }}>
                <Connection
                  id={`DeviceId${item.deviceId}`}
                  type={item.connection}
                />
              </div>
              <div className="d-flex" style={{ width: "55px" }}>
                <i
                  id={`ConfigDevice${item.deviceId}`}
                  className="fas fa-cog cursor-pointer text-hover-primary mr-3"
                />
                <i
                  id={`StopDevice${item.deviceId}`}
                  className={`fas fa-stop-circle ${
                    item.state.toUpperCase() !== "IDLE"
                      ? "cursor-pointer text-danger text-hover-primary"
                      : ""
                  }`}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SpeakerInfo;
