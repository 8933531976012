import axiosClient from "./axiosClient";

const areaApi = {
  getAreas: () => {
    const url = `/areas`;
    return axiosClient.get(url);
  },
  // Lấy các khu vực con của 1 khu vực
  getAreasInAreaId: (areaId) => {
    const url = `/areas/${areaId}`;
    return axiosClient.get(url);
  },

  getDevicesInArea: (areaId, params) => {
    console.log("[AreaApi] getdevice in Area", areaId);
    const url = `/areas/${areaId}/devices`;
    return axiosClient.get(url, { params });
  },

  createArea: (params) => {
    const url = `/areas/create`;
    return axiosClient.put(url, params);
  },

  updateArea: (params) => {
    const url = `/areas/update`;
    return axiosClient.post(url, params);
  },

  deleteArea: (MaKhuVuc) => {
    const url = `/areas/delete`;
    // return axiosClient.delete(url, { data: { MaKhuVuc: `[${MaKhuVuc.toString()}]` } });
    return axiosClient.delete(url, { data: { MaKhuVuc: MaKhuVuc } });
  },
};

export default areaApi;
