import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Utils, { findAreaById, findAreaByMaKhuVuc } from "general/utils/Utils";

import AppPage from "components/AppPage";
import AppCard from "components/AppCard";
import AreaFilter from "components/AreaFilter";
import areaApi from "api/areaApi";
import { AppImages } from "general/constants/AppResource";
import NavBar from "components/NavBar";
import AppTabs from "components/AppTabs";
import stationApi from "api/stationApi";
import DevicesTable from "components/DevicesTable";
import SelectArea from "components/SelectArea/SelectArea";
import deviceApi from "api/deviceApi";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import ToastHelper from "general/helpers/ToastHelper";
import ModalAddDevice from "pages/DeviceManager/components/ModalAddDevice";
import devicesApi from "api/devicesApi";

DeviceManagerScreen.propTypes = {};

function DeviceManagerScreen(props) {
  const { t } = useTranslation();

  // MARK: --- Params ---
  const currentUser = useSelector((state) => state.auth.current);
  const devicesTableRef = useRef();
  const [areasByUser, setAreasByUser] = useState([]);
  const [currentArea, setCurrentArea] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [currentAreaId, setCurrentAreaId] = useState();
  const [searchText, setSearchText] = useState("");
  let tabs = [t("RadioDevice"), t("BulletinBoard")];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [numberOfSelectedDevices, setNumberOfSelectedDevices] = useState(null);
  const [modalAddDeviceShowing, setModalAddDeviceShowing] = useState(false);
  const [reload, setReload] = useState();
  const deleteDevice = async (params) => {
    let deviceIds = params;
    let res = await deviceApi.delete({ deviceIds: deviceIds });
    if (res) {
      if (res.data.result === "success") {
        ToastHelper.showSuccess("Xóa thiết bị thành công");
        setSelectedItems([]);
      } else {
        ToastHelper.showError("Xóa thiết bị thất bại");
      }
    }
  };

  const handleClickDelete = () => {
    devicesTableRef.current.handleDeleteMultipleDevices();
  };

  const handleClickConfigure = () => {
    devicesTableRef.current.handleConfigureMultipleDevices();
  };

  // MARK: --- Functions ---
  function handleSelectedTab(tab) {
    setSelectedTab(tab);
  }

  async function getAreas() {
    const res = await areaApi.getAreas();

    const { MoTa, NoiDung } = res.data;
    if (MoTa == "ThanhCong" && NoiDung) {
      setAreasByUser(NoiDung?.DanhSachKhuVuc);
    }
  }

  // MARK: --- Hooks ---

  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.MaKhuVuc &&
      selectedArea?.MaKhuVuc != currentUser.MaKhuVuc
    ) {
      setSelectedArea(
        findAreaByMaKhuVuc(currentUser.MaKhuVuc ? currentUser.MaKhuVuc : -1)
      );
    }
  }, [currentUser, allAreas]);

  useEffect(() => {
    getAreas();
  }, []);

  useEffect(() => {
    let MaKhuVuc = selectedArea
      ? selectedArea.MaKhuVuc
      : currentUser
      ? currentUser.MaKhuVuc
      : null;
    if (MaKhuVuc) {
      setCurrentAreaId(MaKhuVuc);
    }
  }, [selectedArea, currentUser.MaKhuVuc]);
  useEffect(() => {
    if (!currentArea) {
      setCurrentArea(
        areasByUser
          .filter((area) => area.MaKhuVuc == currentUser?.MaKhuVuc)
          .at(0)
      );
    }
  }, [areasByUser]);

  return (
    <>
      <AppPage
        backgroundImage={AppImages.imgBackground4}
        headerTitle="Quản lý thiết bị"
      >
        <NavBar navBarTitle="Quản lý thiết bị"></NavBar>
        <div className="d-flex flex-column flex-column-fluid my-2">
          <div className="card card-custom">
            {/* card header */}
            <div className="card-header border-0 px-5 py-3">
              <div className="w-100 d-flex justify-content-between flex-wrap gap-2">
                <div className="card-title my-0 d-flex">
                  <h3
                    onClick={() => {
                      // setParentCompanyUnit({});
                      // setRootDataItems(companyUnits);
                      // setArrBreadcrumb([]);
                    }}
                    className="card-label cursor-pointer"
                  >
                    QUẢN LÝ HỆ THỐNG THIẾT BỊ
                  </h3>
                </div>

                {/* header toolbar */}
                <div
                  className="card-toolbar flex-grow-1 justify-content-md-end "
                  style={{ gap: "10px" }}
                >
                  <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-300px mt-4 mt-md-0 px-smaller-md-0 pr-md-0 mr-3 ">
                    <p
                      className="display-none display-md-block font-weight-bold mr-3 w-md-max-content"
                      style={{ color: "#4A5677" }}
                    >
                      {t("Display")}
                    </p>
                    <SelectArea
                      onSelect={(area) => {
                        setSelectedArea(area);
                      }}
                      rootAreaId={currentUser.MaKhuVuc}
                      selectedArea={selectedArea}
                      className="flex-grow-1"
                    />
                  </div>
                  <BaseSearchBar
                    name="searchBar"
                    placeholder={t("Search")}
                    onSubmit={setSearchText}
                    className="w-100 w-md-200px w-xl-250px w-xxxl-300px mr-0 mr-md-6"
                  />

                  <button
                    className="CardButton d-flex flex-row align-items-center"
                    onClick={() => {
                      setModalAddDeviceShowing(true);
                    }}
                  >
                    <i className="far fa-plus-circle" />
                    <p className="Bold_13 text-white text-capitalize m-0">
                      {t("AddDevice")}
                    </p>
                  </button>
                </div>
              </div>
            </div>

            {/* card body */}
            <div className="card-body px-0 pt-0 pb-4">
              <div className="w-100 bg-white border-0">
                <div className="d-flex justify-content-between align-items-end border-0">
                  <AppTabs
                    tabs={tabs.reverse()}
                    activeTab={selectedTab}
                    handleClick={handleSelectedTab}
                    className="flex-grow-1"
                  />
                </div>
              </div>
              {/* {console.log(selectedTab, tabs[0], selectedTab === tabs[0])} */}
              {selectedTab === tabs[0] ? (
                <DevicesTable
                  getDevicesApi={(params) => {
                    let MaKhuVuc = selectedArea
                      ? selectedArea.MaKhuVuc
                      : currentUser.MaKhuVuc;

                    if (MaKhuVuc) {
                      params.MaKhuVuc = MaKhuVuc;

                      return devicesApi.getDigitalsignage(params);
                    }
                  }}
                  configured={true}
                  deviceType={"bulletin-board"}
                  currentAreaId={currentAreaId}
                  searchText={searchText}
                  tableName={selectedTab}
                  setNumberOfSelectedDevices={setNumberOfSelectedDevices}
                  ref={devicesTableRef}
                  tab={selectedTab}
                  reload={reload}
                />
              ) : (
                <DevicesTable
                  getDevicesApi={
                    // (params) => stationApi.getStationDevices(15, params)
                    (params) => {
                      let MaKhuVuc = selectedArea
                        ? selectedArea.MaKhuVuc
                        : currentUser.MaKhuVuc;

                      if (MaKhuVuc) {
                        params.MaKhuVuc = MaKhuVuc;

                        return devicesApi.getDevicesInArea(params);
                      }
                    }
                  }
                  configured={true}
                  currentAreaId={currentAreaId}
                  searchText={searchText}
                  tableName={selectedTab}
                  setNumberOfSelectedDevices={setNumberOfSelectedDevices}
                  ref={devicesTableRef}
                  tab={selectedTab}
                  reload={reload}
                />
              )}
            </div>
          </div>
        </div>
      </AppPage>
      <ModalAddDevice
        show={modalAddDeviceShowing}
        onClose={() => setModalAddDeviceShowing(false)}
        rootAreaId={currentUser.areaId}
        name={selectedTab === tabs[0] ? t("BulletinBoard") : t("RadioDevice")}
        setReload={setReload}
      />
    </>
  );
}

export default DeviceManagerScreen;
