import React, { useEffect, useState, useRef } from "react";
import { AppImages } from "general/constants/AppResource";
import Utils from "general/utils/Utils";
import { useTranslation } from "react-i18next";
import { FieldMetaProps, FieldInputProps } from "formik";

/**
 *
 * @param {Object} props
 * @param {string=} props.className
 * @param {FieldInputProps} props.fieldProps
 * @param {FieldMetaProps} props.fieldMeta
 * @param {string=} props.name
 * @param {boolean=} props.disabled
 * @param {boolean=} props.required
 * @param {boolean=} props.disabledRevokeUrl
 * @param {string=} props.imageClassName
 * @param {(files: File[])=>void} props.onFileSelected
 *
 * @returns
 */
function KeenChangeAvatar(props) {
  // MARK: --- Params ---
  const {
    fieldMeta,
    fieldProps,
    name,
    disabled,
    onFileSelected,
    className,
    required,
    disabledRevokeUrl,
    imageClassName,
    idInput = "",
  } = props;
  // console.log(props);
  const value = decodeURI(fieldProps.value);
  // console.log(value);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState(value ?? AppImages.imgDefaultAvatar);

  useEffect(() => {
    if (selectedAvatar !== null) {
      console.log(URL.createObjectURL(selectedAvatar));
      setAvatar(URL.createObjectURL(selectedAvatar));
    }
  }, [selectedAvatar]);

  useEffect(() => {
    if (value) setAvatar(value);
  }, [value]);

  const { touched } = fieldMeta;
  const showError =
    required && touched && (Utils.isObjectNull(avatar) || avatar === "");

  // MARK: --- Hooks ---
  useEffect(() => {
    return () => {
      if (!Utils.isObjectNull(avatar) && !disabledRevokeUrl) {
        URL.revokeObjectURL(avatar);
      }
    };
  }, [avatar]);

  // MARK: --- Functions ---
  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  function handleAvatarChange(e) {
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      setSelectedAvatar(selectedFile);

      if (_.isFunction(onFileSelected)) {
        onFileSelected(files);
      }
    }
  }

  /**
   * @type {React.LegacyRef<HTMLInputElement}
   */
  const inputRef = useRef();

  return (
    <div className={`${className}`}>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          inputRef.current?.click();
        }}
        className={`image-input image-input-empty image-input-outline ${imageClassName}`}
        style={{ backgroundImage: `url(${Utils.getFullUrl(avatar)})` }}
      >
        <div
          className={`image-input-wrapper ${imageClassName} ${
            showError && "border-1 border-danger"
          }`}
        ></div>
      </a>
      <div className="fv-plugins-message-container">
        {showError && <div className="fv-help-block">{t("Required")}</div>}
      </div>
      <input
        id={idInput}
        ref={inputRef}
        disabled={!!disabled}
        type="file"
        name={name}
        style={{ display: "none" }}
        accept=".png, .jpg, .jpg"
        onChange={handleAvatarChange}
      />
    </div>
  );
}

export default KeenChangeAvatar;
