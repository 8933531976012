import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { AppIcons } from "general/constants/AppResource";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useSelector } from "react-redux";
import { DropdownItem } from "reactstrap";

import { Dropdown } from "react-bootstrap";
import AccountDropdown from "components/AccountDropdown";
import NotificationDropdown from "components/NotificationDropdown";
import PreferenceKeys from "general/constants/PreferenceKeys";
import { useHistory } from "react-router-dom";
import Utils from "general/utils/Utils";
import authApi from "api/authApi";

NavBar.propTypes = {
  navBarTitle: PropTypes.string,
};

NavBar.defaultProps = {
  navBarTitle: "",
};

function NavBar(props) {
  const { t } = useTranslation();
  const [navBarToggle, setNavBarToggle] = useState(false);
  const [isMedium, setIsMedium] = useState(null);
  const [checkNoti, setCheckNoti] = useState(false);

  const history = useHistory();
  const level = ["Tỉnh", "Huyện", "Xã"];

  const [notiContents, setNotiContents] = useState(null);

  useEffect(async () => {
    const res = await authApi.getNotifications();
    if (res?.data?.notifications) {
      setCheckNoti(true);
      setNotiContents(res?.data?.notifications);
    }
  }, []);

  const handleScroll = (event) => {
    let scrollY = window.scrollY;
    if (scrollY > 60) {
      setNavBarToggle(true);
    } else {
      setNavBarToggle(false);
    }
  };

  const handleResize = (event) => {
    if (window.innerWidth < 992) {
      setIsMedium(true);
    } else {
      setIsMedium(false);
    }
  };

  const currentUser = useSelector((state) => state.auth.current);
  const avatarLink = Utils.isObjectEmpty(currentUser?.avatar)
    ? null
    : Utils.getFullUrl(currentUser?.AnhDaiDien);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let navBarPosition;
  if (navBarToggle) {
    navBarPosition = {
      top: "0px",
    };
  } else {
    navBarPosition = {
      top: "-300px",
    };
  }

  return (
    <div
      className={`bg-white navBar shadow-lg`}
      id="kt_header"
      style={{ ...navBarPosition }}
    >
      <div className="d-flex h-100 justify-content-between align-items-center px-6">
        <p
          className="font-weight-boldest font-size-h6 text-uppercase "
          style={{ color: "#4A5677" }}
        >
          {props.navBarTitle}
        </p>

        <div className="d-flex flex-row align-items-center h-100 position-relative">
          <button
            className=" d-flex flex-row align-items-center shadow-sm playButton px-4"
            // style={{ backgroundColor: "#3ACE5A" }}
            onClick={() => history.push("/bulletins/edit/speaker")}
          >
            <i className="far fa-play-circle"></i>
            <p className="Bold_13 " style={{ color: "white" }}>
              {t("PlayNow")}
            </p>
          </button>
          <img src={AppIcons.icSperator} className="ml-4" />

          <NotificationDropdown notiContents={notiContents}>
            <div className="btn btn-secondary navBarButton d-flex flex-row align-items-center justify-content-center mx-4">
              {checkNoti ? (
                <>
                  <i
                    className="fas fa-bell p-0 "
                    style={{
                      color: "#5180FB",
                      position: "relative",
                      left: "4px",
                    }}
                  />
                  <img
                    src={AppIcons.icDotRed}
                    style={{ position: "relative", bottom: "6px" }}
                  />
                </>
              ) : (
                <i className="fas fa-bell p-0 " style={{ color: "#5180FB" }} />
              )}
              {/* <i
                className="fas fa-bell p-0 "
                style={{ color: "#5180FB", opacity: "" }}
              ></i> */}
            </div>
          </NotificationDropdown>

          <img src={AppIcons.icSperator} className="mr-4" />

          <AccountDropdown>
            <div
              className=" btn-secondary navBarButton d-flex  align-items-center justify-content-center"
              style={{ borderRadius: "50%" }}
            >
              {/* <i
                className="fas fa-user p-0"
                style={{ color: "#5180FB", opacity: "" }}
              ></i> */}
              {avatarLink != null ? (
                <img
                  src={avatarLink}
                  className="rounded "
                  style={{ width: "38px", height: "38px", objectFit: "cover" }}
                ></img>
              ) : (
                <i
                  className="fas fa-user p-0"
                  style={{ color: "#5180FB", opacity: "" }}
                ></i>
              )}
            </div>
          </AccountDropdown>

          <div className="ml-4 d-flex flex-column justify-content-center">
            <div style={{ width: "130px" }}>
              <p
                className="font-weight-bolder font-size-lg"
                style={{
                  color: "#5180FB",
                  fontSize: "15px",
                  lineHeight: "18px",
                }}
              >
                {currentUser?.Ten}
              </p>
            </div>
            <p
              className="font-weight-bold"
              style={{ color: "#4A5677", fontSize: "12px", lineHeight: "14px" }}
            >
              {t("AccountLevel")} : {level[currentUser?.Cap]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
