import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppPage from "../../../../components/AppPage";
import {
  setEditAccount,
  setPagination,
  thunkSearchAccount,
} from "../../accountSlice";
import {
  AccountPagination,
  Account,
  Area,
} from "../../../../general/types/index.d";
import { AppIcons, AppImages } from "../../../../general/constants/AppResource";
import { useTranslation } from "react-i18next";
import NavBar from "../../../../components/NavBar";
import AppCard from "../../../../components/AppCard";
import AreaFilter from "../../../../components/AreaFilter";
import DataTable, { TableColumn } from "react-data-table-component";
import customDataTableStyle from "../../../../assets/styles/customDataTableStyle";
import CheckBox from "../../../../components/CheckBox";
import AppPagination from "../../../../components/AppPagination";
import Global from "../../../../general/Global";
import areaApi from "../../../../api/areaApi";
import SelectArea from "../../../../components/SelectArea/SelectArea";
import BaseSearchBar from "../../../../general/custom-fields/BaseSearchBar";
import _ from "lodash";
import { setAllAreas, thunkGetAreas } from "../../../AreaManager/areaSlice";
import Empty from "../../../../components/Empty";
import ModalEditAccount from "../ModalEditAccount";
import ModalDeleteAccount from "../../components/ModalDeleteAccount";
import userApi from "../../../../api/userApi";
import ToastHelper from "general/helpers/ToastHelper";
import Loading from "components/Loading";
import { useHistory } from "react-router-dom";
import { findAreaById, findNode } from "general/utils/Utils";
import TreeItemDropdown from "pages/AccountManager/components/TreeItemDropdown";

export default function AccountManagerHomeScreen() {
  const currentUser = useSelector((state) => state.auth.current);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedArea, setSelectedArea] = useState();

  /**
   * @type {{loadingState, pagination:AccountPagination, accounts:Account[]}}
   */
  const { loadingState, pagination, accounts } = useSelector(
    (state) => state.account
  );
  /**
   * @type {[Account[],React.Dispatch<React.SetStateAction<Account[]>>]}
   */
  const [selectedUsers, setSelectedUsers] = useState([]);
  /**
   * @type {Area[]}
   */
  const allAreas = useSelector((state) => state.area.allAreas);

  useEffect(() => {
    handleRefresh();
  }, [
    pagination.filters.areaId,
    pagination.filters.state,
    pagination.limit,
    pagination.page,
    pagination.q,
    pagination.order,
  ]);
  const history = useHistory();
  const handleRefresh = () => dispatch(thunkSearchAccount(pagination));
  const clearSelectedUser = () => setSelectedUsers([]);

  /**
   * @type {Account}
   */
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.areaId > 0 &&
      selectedArea?.AreaId != currentUser.areaId
    ) {
      setSelectedArea(
        findAreaById(currentUser.areaId ? currentUser.areaId : -1)
      );
    }
  }, [currentUser, allAreas]);

  /**
   * @type {TableColumn<Account>[]}
   */
  const columns = [
    {
      id: "checkbox",
      name: (
        <CheckBox
          checked={selectedUsers.length == accounts.length}
          onCheck={() => setSelectedUsers(accounts)}
          onUncheck={clearSelectedUser}
        />
      ),
      selector: (row) => (
        <CheckBox
          checked={
            !!selectedUsers.find((item) => item.MaNguoiDung == row.MaNguoiDung)
          }
          className="ml-2"
          onCheck={() => {
            setSelectedUsers(_.uniqBy([...selectedUsers, row], "MaNguoiDung"));
          }}
          onUncheck={() => {
            setSelectedUsers(
              selectedUsers.filter(
                (item) => item.MaNguoiDung != row.MaNguoiDung
              )
            );
          }}
        />
      ),
      allowOverflow: true,
      grow: 0,
      width: "80px",
      center: true,
    },
    // {
    //   id: "id",
    //   name: t("ID"),
    //   selector: (row) => row.id,
    //   style: { fontWeight: "bold", color: "#3699FF" },
    // },
    {
      id: "TaiKhoan",
      name: t("Username"),
      selector: (row) => row.TaiKhoan,
      style: { fontWeight: "bold", color: "#3699FF" },
      sortable: true,
      minWidth: "130px",
    },
    {
      id: "MaKhuVuc",
      name: t("Area"),
      selector: (row) => `${findNode(allAreas, row?.MaKhuVuc)?.Ten}`,
      style: { fontWeight: "500" },
      wrap: true,
      sortable: true,
      minWidth: "100px",
    },
    {
      id: "TrangThai",
      name: t("State"),
      selector: (row) => (
        <div style={{ width: 120 }}>
          <div
            className={`d-inline-flex status-box`}
            style={{
              padding: "4px 8px 4px 8px",
              borderRadius: "4px",
              ...(row.TrangThai == "HoatDong"
                ? { backgroundColor: "rgba(32, 161, 68, 0.1)" }
                : null),
              ...(row.TrangThai == "VoHieuHoa"
                ? { backgroundColor: "rgba(229, 65, 53, 0.1)" }
                : null),
            }}
          >
            <p
              className="Regular_13 text-capitalize"
              style={{
                ...(row.TrangThai == "HoatDong"
                  ? { color: "#20A144" }
                  : undefined),
                ...(row.TrangThai == "VoHieuHoa"
                  ? { color: "#E64135" }
                  : undefined),
              }}
            >
              {row.TrangThai == "HoatDong" ? t("active") : t("disactive")}
            </p>
          </div>
        </div>
      ),
      sortable: true,
      minWidth: "130px",
    },
    {
      name: t("Manage"),
      width: "100px",
      selector: (row) => (
        <div
          className="Manage"
          style={{
            ...(row.area?.level < currentUser?.area?.level
              ? { display: "none" }
              : null),
          }}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              // moveToEditAccountTab(row.id);
              setSelectedAccount(row);
              setModalVisible(true);
            }}
          >
            <i className="fa fa-cog cursor-pointer mr-5 text-hover-primary"></i>
          </a>

          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleDelete([row]);
            }}
          >
            <i className="fa fa-trash-alt cursor-pointer mr-5 text-hover-danger"></i>
          </a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    console.log({ accounts });
  }, [accounts]);

  async function getAreas() {
    // setIsLoading(true)
    // const res = await areaApi.getAreas();
    // /**
    //  * @type {{areas:Area[]}}
    //  */
    // const { MoTa, NoiDung } = res.data;
    // if (MoTa == "success" && NoiDung.DanhSachKhuVuc) {
    //   dispatch(setAllAreas(NoiDung.DanhSachKhuVuc));
    // }
    dispatch(thunkGetAreas());
  }

  useEffect(() => {
    getAreas();
  }, []);

  /**
   *
   * @param {Account[]} users
   */
  const handleDelete = (users) => {
    showDeleteModal(users);
  };
  const moveToEditAccountTab = (row) => {
    // let currentUrl = String(window.location.origin);
    dispatch(setEditAccount(row));
    let accountLink = "/account/";
    accountLink = accountLink.concat(row.id);
    history.push(accountLink);
  };

  /* selected edit account */
  /**
   * @type {[Account,React.Dispatch<React.SetStateAction<Account>>]}
   */
  const [selectedAccount, setSelectedAccount] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);

  /**
   *
   * @param {Account} user
   */
  const editAccount = (user) => {
    setSelectedAccount(user);
    setModalVisible(true);
  };

  /**
   *
   * @param {boolean=} success
   */
  const onModalDismiss = (success) => {
    setModalVisible(false);
    setSelectedAccount(undefined);

    if (!!success) {
      handleRefresh();
    }
  };

  const addAccount = () => {
    // let currentUrl = String(window.location.origin);
    // let addAccountLink = "/account/add";
    // history.push(addAccountLink);
    setModalVisible(true);
  };

  const onDeletePress = () => {
    handleDelete([selectedAccount]);
  };

  /* delete selected account */
  const [modalDeleteShowing, setModalDeleteShowing] = useState(false);

  /**
   * selected account for deleting
   * @type {[Account[],React.Dispatch<React.SetStateAction<Account[]>>]}
   */
  const [selectedForDelete, setSelectedForDelete] = useState([]);

  /**
   *
   * @param {Account[]} user
   * @returns
   */
  const showDeleteModal = (user) => {
    setSelectedForDelete(user);
    setModalDeleteShowing(true);
  };
  const hideDeleteModal = () => {
    setModalDeleteShowing(false);
    setSelectedForDelete([]);
  };

  /* refresh selected account */
  useEffect(() => {
    if (!loadingState) {
      setSelectedUsers([]);
      setSelectedForDelete([]);
    }
  }, [loadingState]);

  return (
    <AppPage
      backgroundImage={AppImages.areaBackground}
      headerTitle={t("UserManagement").toUpperCase()}
    >
      <NavBar navBarTitle={t("UserManagement")}></NavBar>
      <div className="d-flex flex-column flex-column-fluid my-2">
        <AppCard
          title="Danh sách người dùng"
          buttonText={t("AddUser")}
          clickAdd={addAccount}
          // isLoading={!!loadingState}
          additionalHeader={
            <>
              <div className="col-12 col-md-3 max-w-md-300px px-smaller-md-0 pl-md-0">
                <BaseSearchBar
                  name="searchBar"
                  placeholder={t("Search")}
                  onSubmit={(text) => {
                    dispatch(setPagination({ q: text }));
                  }}
                  defaultValue={pagination.q}
                  className="w-100"
                />
              </div>
              {/* <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0 pr-md-0">
                <p
                  className="display-none display-md-block font-weight-bold mr-3 w-md-max-content"
                  style={{ color: "#4A5677" }}
                >
                  {t("Display")}
                </p> */}
              {/* <SelectArea
                  rootAreaId={currentUser?.areaId}
                  selectedArea={
                    selectedArea
                      ? selectedArea
                      : _.forOwn(
                          {
                            ...allAreas.find(
                              (area) => area.id == pagination.filters.areaId
                            ),
                          },
                          (value, key, object) => {
                            if (key == "id") object["AreaId"] = value;
                            else if (key == "name") object["AreaName"] = value;
                          }
                        )
                  }
                  onSelect={(area: Area) => {
                    setSelectedArea(null);
                    if (area["AreaId"]) area["id"] = area["AreaId"];

                    dispatch(
                      setPagination({
                        filters: { ...pagination.filters, areaId: area.id },
                      })
                    );
                  }}
                  className="flex-grow-1"
                /> */}
              {/* <TreeItemDropdown tree={allAreas} /> */}
              {/* </div> */}
              <div className="w-100">
                {!!selectedUsers.length && (
                  <button
                    className="CardButton btn btn-light-danger d-flex flex-row align-items-center mt-6"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDelete(selectedUsers);
                    }}
                  >
                    <i className="fa fa-user-minus"></i>
                    <p
                      className="font-weight-bold text-capitalize m-0"
                      style={{ fontSize: 13 }}
                    >
                      {`${t("Delete")} ${selectedUsers.length}`}
                    </p>
                  </button>
                )}
              </div>
            </>
          }
        >
          <DataTable
            responsive
            progressPending={!!loadingState}
            progressComponent={
              <div className="d-flex align-items-center justify-content-center p-10">
                <Loading message={`${t("Loading")}...`} />
              </div>
            }
            columns={columns}
            data={accounts}
            onRowClicked={(row) => {
              setSelectedAccount(row);
              setModalVisible(true);
            }}
            noDataComponent={
              <div className="d-flex align-items-center justify-content-center py-10">
                <Empty
                  buttonText={t("Refresh")}
                  onClickButton={handleRefresh}
                  text={t("NoDataToDisplay")}
                />
              </div>
            }
            // selectableRows
            customStyles={{
              ...customDataTableStyle,
            }}
            conditionalRowStyles={[
              {
                when: (row) =>
                  !(accounts.findIndex((item) => item.id == row.id) % 2),
                style: { backgroundColor: "#F6F7FB" },
              },
              {
                when: (row) =>
                  !!selectedUsers.find((item) => item.id == row.id),
                style: { backgroundColor: "#DBE3EF" },
              },
            ]}
            pointerOnHover
            highlightOnHover
            pagination
            paginationServer
            paginationComponent={({
              currentPage,
              onChangePage,
              onChangeRowsPerPage,
              rowCount,
              rowsPerPage,
            }) => (
              <AppPagination
                curPage={pagination.page + 1}
                curStep={pagination.limit}
                setPage={(page) => {
                  dispatch(setPagination({ page: page - 1 }));
                }}
                setStep={(step) => {
                  dispatch(setPagination({ limit: step }));
                }}
                step={Global.gAvailablePagination}
                total={pagination.total}
              />
            )}
            sortServer
            onSort={(column, order) => {
              dispatch(setPagination({ order: [column.id, order] }));
            }}
          />
        </AppCard>
      </div>

      {/* modal edit account */}
      <ModalEditAccount
        onDismiss={onModalDismiss}
        account={selectedAccount}
        show={modalVisible}
        handleDelete={onDeletePress}
      />
      {/* modal delete */}
      <ModalDeleteAccount
        show={modalDeleteShowing}
        onClose={hideDeleteModal}
        length={selectedForDelete}
        message={t("SureDeleteAccounts", { length: selectedForDelete.length })}
        onSubmit={async () => {
          try {
            let { MoTa } = (
              await userApi.deleteUsers(
                selectedForDelete.map((user) => user.MaNguoiDung)
              )
            ).data;

            if (MoTa == "ThanhCong") {
              onModalDismiss();
              handleRefresh();
              ToastHelper.showSuccess(t("DeleteUserSuccess"));
            } else {
              ToastHelper.showSuccess(t("DeleteUserFailed"));
            }
          } catch (error) {}
          hideDeleteModal();
        }}
      />
    </AppPage>
  );
}
