import { param } from "jquery";
import axiosClient from "./axiosClient";

const dashboardApi = {
  getDashboard: (params) => {
    const { startDate, endDate, MaKhuVuc } = params;
    // console.log(startDate, endDate);
    const url =
      "/dashboard?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&MaKhuVuc=" +
      MaKhuVuc;
    // const url = 'dashboard?startDate=2022/07/12&endDate=2022/07/18'
    return axiosClient.get(url, {
      startDate: startDate,
      endDate: endDate,
    });
  },

  getBulletins: (params) => {
    const { areaId, startDate, endDate, page, limit } = params;
    const url =
      "/bulletins/?areaId=" +
      areaId +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&q&page=" +
      page +
      "&limit=" +
      limit +
      '&orderField=["startDate"]&orderMode=DESC&deviceId';
    return axiosClient.get(url, {
      areaId: areaId,
      startDate: startDate,
      endDate: endDate,
      page: page,
      limit: limit,
      orderField: "startDate",
    });
  },
};

export default dashboardApi;
