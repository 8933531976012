import imgAdminAvatarDefault from "assets/images/img_admin_avatar_default.svg";
import AppData from "general/constants/AppData";
import { AppIcons, AppImages } from "general/constants/AppResource";
import Utils, { getRandomInt } from "general/utils/Utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.scss";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import NavBar from "components/NavBar";
import PreferenceKeys from "general/constants/PreferenceKeys";
import { Dropdown } from "react-bootstrap";
import AccountDropdown from "components/AccountDropdown";
import NotificationDropdown from "components/NotificationDropdown";
import authApi from "api/authApi";
import { Animated } from "react-animated-css";
import Global from "general/Global";
import { SuspenseImg } from "components/SuspenseImage";
import _ from "lodash";
Header.propTypes = {};

function Header(props) {
  // MARK: --- Params ---
  const history = useHistory();
  // const [headerBackground, setHeaderBackground] = useState(
  //   AppImages.headerBackgoundImages[
  //     getRandomInt(0, AppImages.headerBackgoundImages.length - 1)
  //   ]
  // );
  // const [headerBackground, setHeaderBackground] = useState(
  //   _.shuffle(AppImages.headerBackgoundImagesSlider)
  // );
  const [headerBackground, setHeaderBackground] = useState(
    _.shuffle(AppImages.headerBackgoundImagesSlider)
  );
  const activePath = history.location.pathname;
  const arrPaths = activePath.split("/").filter((item) => {
    return item !== "";
  });
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // const currentUser = useSelector(state => state.user.current);
  // const { Avatar, Username } = currentUser;
  // const Username = 'Admin';
  const currentUser = useSelector((state) => state.auth.current);
  const [checkNoti, setCheckNoti] = useState(false);
  const avatarLink = Utils.isObjectEmpty(currentUser?.AnhDaiDien)
    ? null
    : Utils.getFullUrl(currentUser?.AnhDaiDien);
  let currentAppLanguage = i18n.language;
  if (!currentAppLanguage) {
    currentAppLanguage = "en";
  }
  let currentAppLanguageItem = AppData.languages.find(
    (item) => currentAppLanguage.indexOf(item.code) !== -1
  );
  if (!currentAppLanguageItem) {
    currentAppLanguageItem = AppData.languages[0];
  }
  const [languageMenuVisible, setLanguageMenuVisible] = useState(false);

  // MARK: --- Functions ---
  function handleChangedLanguage(lang) {
    setLanguageMenuVisible(false);
    i18n.changeLanguage(lang);
  }

  function toggleLanguageMenu() {
    setLanguageMenuVisible(!languageMenuVisible);
  }
  const [notiContents, setNotiContents] = useState(null);
  useEffect(async () => {
    const res = await authApi.getNotifications();

    if (res?.data?.notifications) {
      setCheckNoti(true);
      setNotiContents(res?.data?.notifications);
    }
  }, []);

  function handleSignOut() {
    localStorage.removeItem(PreferenceKeys.accessToken);
    localStorage.removeItem(PreferenceKeys.refreshToken);
    history.push("/auth/sign-in");
  }
  if (props.backgroundImage || props.random) {
    return (
      <>
        <div
          className="d-flex flex-row justify-content-between align-items-baseline px-6 "
          style={{ zIndex: "10", height: 60 }}
        >
          <h4 className="text-white font-weight-boldest font-size-h6 my-1 mr-3 mt-5 text-uppercase">
            {props.headerTitle}
          </h4>
          <div className="mt-5 d-flex flex-row align-items-center">
            <button
              className=" d-flex align-items-center p-3 playButton "
              onClick={() => history.push("/bulletins/edit/speaker")}
            >
              <i className="far fa-play-circle"></i>
              <p className="Bold_13 " style={{ color: "white" }}>
                {t("PlayNow")}
              </p>
            </button>
            <img src={AppIcons.icSperator} className="ml-4" />

            <NotificationDropdown moveRight={false} notiContents={notiContents}>
              <div className="btn btn-secondary headerSquareButton d-flex flex-row align-items-center justify-content-center shadow-sm mx-4">
                {checkNoti ? (
                  <>
                    <i
                      className="fas fa-bell p-0 "
                      style={{
                        color: "#4A5677",
                        position: "relative",
                        left: "4px",
                      }}
                    />
                    <img
                      src={AppIcons.icDotRed}
                      style={{ position: "relative", bottom: "6px" }}
                    />
                  </>
                ) : (
                  <i
                    className="fas fa-bell p-0 "
                    style={{ color: "#4A5677" }}
                  />
                )}
              </div>
            </NotificationDropdown>

            <AccountDropdown moveRight={false}>
              <div className="btn btn-secondary headerSquareButton d-flex flex-row align-items-center justify-content-center shadow-sm  ">
                {avatarLink != null ? (
                  <img
                    src={avatarLink}
                    className="rounded "
                    style={{
                      width: "38px",
                      height: "38px",
                      objectFit: "cover",
                    }}
                  ></img>
                ) : (
                  <i
                    className="fas fa-user p-0 "
                    style={{ color: "#4A5677", opacity: "" }}
                  ></i>
                )}
              </div>
            </AccountDropdown>
          </div>
        </div>

        <div
          className="position-absolute"
          style={{
            top: "20%",
            left: "300px",
            zIndex: "10",
            transform: "translate(0,-20%)",
          }}
        ></div>
        {/* <Animated
          animationIn={Global.animationIn}
          animationOut={Global.animationOut}
          animationInDuration={Global.animationInDuration}
          animationOutDuration={Global.animationOutDuration}
          isVisible={true}
          className=" header pt-0 "
          style={{ marginBottom: "-18%", zIndex: "0", marginTop: -60 }}
        > */}

        {/* <div
          className="header pt-0 "
          style={{ marginBottom: "-18%", zIndex: "0", marginTop: -60 }}
        >
          {props.random ? (
            <SuspenseImg
              className="imageDiv w-100"
              alt=""
              src={headerBackground}
            ></SuspenseImg>
          ) : (
            // <div
            //   className=" imageDiv w-100"
            //   style={{
            //     backgroundImage: `url(${headerBackground})`,
            //   }}
            // ></div>
            <div
              className=" imageDiv w-100"
              style={{ backgroundImage: `url(${props.backgroundImage})` }}
            ></div>
          )}
        </div>
          */}
        {/* </Animated> */}

        <div
          className="header pt-0 w-100"
          style={{ marginBottom: "-18%", zIndex: "0", marginTop: -60 }}
        >
          <Swiper
            loop={true}
            // pagination={{
            //   dynamicBullets: true,
            // }}
            modules={[Autoplay]}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            className="mySwiper"
          >
            {headerBackground?.map((item, index) => (
              <SwiperSlide key={index}>
                {" "}
                <SuspenseImg
                  className="imageDiv w-100"
                  alt=""
                  src={item}
                ></SuspenseImg>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>
    );
  } else {
    return (
      <div className="bg-white pt-0 w-100 " style={{}}>
        <div className="d-flex flex-row justify-content-between align-items-center px-6 py-5">
          <p
            className="font-weight-boldest font-size-h6 text-uppercase "
            style={{ color: "#4A5677" }}
          >
            {props.headerTitle}
          </p>

          <div className="d-flex flex-row align-items-center h-100">
            <button className=" d-flex flex-row align-items-center shadow-sm p-3 playButton ">
              <i className="far fa-play-circle"></i>
              <p className="Bold_13 " style={{ color: "white" }}>
                {t("PlayNow")}
              </p>
            </button>
            <img src={AppIcons.icSperator} className="mx-4" />
            <Dropdown className="">
              <Dropdown.Toggle
                className="dropdown-toggle d-flex justify-content-center align-items-center bg-white p-0 border-0 "
                style={{ borderRadius: "50%" }}
              >
                <div className="btn btn-secondary navBarButton d-flex flex-row align-items-center justify-content-center">
                  <i
                    className="fas fa-bell p-0 "
                    style={{ color: "#3465E5", opacity: "0.4" }}
                  ></i>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100 font-size-lg shadow-lg">
                <DropdownItem>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-user min-w-25px text-primary"></i>
                    <span className="font-size-lg">test</span>
                  </div>
                </DropdownItem>
                <DropdownItem divider></DropdownItem>
                <DropdownItem onClick={handleSignOut}>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-user min-w-25px text-primary"></i>
                    <span className="font-size-lg">{t("SignOut")}</span>
                  </div>
                </DropdownItem>
              </Dropdown.Menu>
            </Dropdown>

            <img src={AppIcons.icSperator} className="mx-4" />

            <div className="ml-4 d-flex flex-column justify-content-center">
              <p
                className="font-weight-bolder font-size-lg"
                style={{
                  color: "#5180FB",
                  fontSize: "15px",
                  lineHeight: "18px",
                }}
              >
                {currentUser?.Ten}
              </p>
              <p
                className="font-weight-bold"
                style={{
                  color: "#4A5677",
                  fontSize: "12px",
                  lineHeight: "14px",
                }}
              >
                {t("AccountLevel")}:{t("Province")}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
