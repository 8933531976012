import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import "./style.scss";
import BaseTextField from "general/custom-fields/BaseTextField";
import {
  addArea,
  thunkGetAreas,
  updateArea,
} from "pages/AreaManager/areaSlice";
import areaApi from "api/areaApi";
import ToastHelper from "general/helpers/ToastHelper";

ModalCreateArea.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  editableArea: PropTypes.object,
  parentArea: PropTypes.object,
};

ModalCreateArea.defaultProps = {
  show: false,
  onClose: null,
  editableArea: null,
  parentArea: null,
};

function ModalCreateArea(props) {
  // MARK: --- Params ---
  const { show, onClose, editableArea, parentArea } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentArea } = useSelector((state) => state.area);

  const formik = useFormik({
    initialValues: {
      Ten: "",
      MaKhuVuc: "",
      MaKhuVucCha: "",
      ViDo: "",
      KinhDo: "",
    },
    onSubmit: (values) => {
      if (editableArea)
        handleUpdateArea({
          // id: editableArea.id,
          MaKhuVuc: values.MaKhuVuc,
          Ten: values.Ten,
          ViDo: values.ViDo,
          KinhDo: values.KinhDo,
        });
      else
        handleAddArea({
          MaKhuVucCha: parentArea?.MaKhuVuc ?? values?.MaKhuVuc,
          Ten: values.Ten,
          MaKhuVuc: values.MaKhuVuc,
          ViDo: values.ViDo,
          KinhDo: values.KinhDo,
        });
      handleClose();
    },
    validationSchema: yup.object({
      Ten: yup.string().required(t("Required")),
      ViDo: yup
        .number()
        .typeError("Tọa độ không bao gồm chữ")
        .min(-90, "Giá trị phải lớn hơn -90")
        .max(+90, "Giá trị phải nhỏ hơn +90")
        .required(t("Required")),
      KinhDo: yup
        .number()
        .typeError("Tọa độ không bao gồm chữ")
        .min(-180, "Giá trị phải lớn hơn -180")
        .max(+180, "Giá trị phải nhỏ hơn +180")
        .required(t("Required")),
    }),
  });

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
      formik.handleReset();
    }
  }

  async function handleAddArea(params) {
    const res = await areaApi.createArea(params);
    const { MoTa, NoiDung } = res.data;

    if (MoTa == "ThanhCong") {
      ToastHelper.showSuccess(t("CreatedAreaSuccessfully"));
      dispatch(thunkGetAreas());
    }
  }

  async function handleUpdateArea(params) {
    const res = await areaApi.updateArea(params);
    const { MoTa, NoiDung } = res.data;

    if (MoTa == "ThanhCong") {
      ToastHelper.showSuccess(t("UpdatedAreaSuccessfully"));
      dispatch(thunkGetAreas());
    }
  }
  // MARK: --- Hooks ---

  useEffect(() => {
    if (editableArea) {
      editableArea?.Ten &&
        formik.getFieldHelpers("Ten").setValue(editableArea?.Ten);
      editableArea?.MaKhuVuc &&
        formik.getFieldHelpers("MaKhuVuc").setValue(editableArea?.MaKhuVuc);
      editableArea?.MaKhuVucCha &&
        formik
          .getFieldHelpers("MaKhuVucCha")
          .setValue(editableArea?.MaKhuVucCha);
      editableArea?.ViDo &&
        formik.getFieldHelpers("ViDo").setValue(editableArea?.ViDo);
      editableArea?.KinhDo &&
        formik.getFieldHelpers("KinhDo").setValue(editableArea?.KinhDo);
    }
  }, [editableArea]);

  return (
    <div>
      <Modal
        className="font-weight-bold"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>{t("Area")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="MaKhuVuc"
            label={t("AreaId")}
            placeholder={t("AreaId")}
            fieldProps={formik.getFieldProps("MaKhuVuc")}
            fieldHelpers={formik.getFieldHelpers("MaKhuVuc")}
            fieldMeta={formik.getFieldMeta("MaKhuVuc")}
          />

          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="Ten"
            label={t("AreaName")}
            placeholder={t("AreaName")}
            fieldProps={formik.getFieldProps("Ten")}
            fieldHelpers={formik.getFieldHelpers("Ten")}
            fieldMeta={formik.getFieldMeta("Ten")}
          />

          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="KinhDo"
            label={t("Longitude")}
            placeholder={t("Longitude")}
            fieldProps={formik.getFieldProps("KinhDo")}
            fieldHelpers={formik.getFieldHelpers("KinhDo")}
            fieldMeta={formik.getFieldMeta("KinhDo")}
          />

          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="ViDo"
            label={t("Latitude")}
            placeholder={t("Latitude")}
            fieldProps={formik.getFieldProps("ViDo")}
            fieldHelpers={formik.getFieldHelpers("ViDo")}
            fieldMeta={formik.getFieldMeta("ViDo")}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div className="row w-100">
            <div className="col-6">
              <a
                className="w-100 btn btn-light font-weight-bold"
                onClick={() => handleClose()}
              >
                {t("Cancel")}
              </a>
            </div>

            <div className="col-6">
              <a
                className="w-100 btn btn-primary font-weight-bold"
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                {t("Save")}
              </a>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalCreateArea;
