import React from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";
DonutChart.propTypes = {
  donutData: PropTypes.array,
  labels: PropTypes.array,
  options: PropTypes.array,
  name: PropTypes.string,
  wDonut: PropTypes.number,
  wScreen: PropTypes.number,
};

DonutChart.defaultProps = {
  show: false,
  onClose: null,
  options: [],
  name: "Tổng số",
  wDonut: 250,
};
function DonutChart(props) {
  const { donutData, labels, name, wDonut, hDonut, wScreen } = props;
  var options = {
    chart: {
      id: "donut-chart",
      width: 400,
      height: 300,
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        expandOnClick: false,
        donut: {
          size: "80%",
          // borderWidth: "10px",
          labels: {
            show: true,
            name: {
              show: true,
              offsetY:
                wScreen > 992 ? (wDonut < 350 ? 20 : 30) : 20 + hDonut / 11,
            },
            value: {
              show: true,
              offsetY:
                wScreen > 992 ? (wDonut < 350 ? -20 : -30) : -20 - hDonut / 11,
              fontSize:
                wScreen > 992 ? (wDonut < 350 ? 30 : 33) : 30 + wDonut / 15,
              color: "#4A5677",
              fontFamily: "Inter",
              fontWeight: 700,
            },
            total: {
              show: true,
              showAlways: true,
              label: name,
              fontSize:
                wScreen > 992 ? (wDonut < 350 ? 20 : 22) : 20 + wDonut / 18,
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#9EA5BD",
              value: {
                show: true,
              },
              // formatter: () => 'Text you want'
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: [
      "Thiết bị đang phát",
      "Thiết bị đang dừng",
      "Thiết bị mất kết nối",
    ],
    colors: ["#3ACE5A", "#9EA5BD", "#E54135"],
    fill: {
      type: "gradient",
    },
    legend: {
      position: "bottom",
      show: false,
      horizontalAlign: "left",
      formatter: function (seriesName, opts) {
        return [
          seriesName,
          " - -------",
          opts.w.globals.series[opts.seriesIndex],
        ];
      },
      fontSize: "13",
      fontFamily: "Inter",
    },
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         width: 150,
    //       },
    //       legend: {
    //         position: "bottom",
    //       },
    //     },
    //   },
    // ],
  };
  console.log(donutData);
  let series = donutData
    ? [
        donutData.DangPhat ?? 0,

        donutData.DangNghi ?? 0,
        donutData.TongSo - donutData.DangHoatDong ?? 0,
      ]
    : [0, 0, 0];

  return (
    <div>
      <Chart options={options} series={series} type="donut" />
    </div>
  );
}

export default DonutChart;
