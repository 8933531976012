import React from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import Footer from "components/Footer";
import AppColors from "general/constants/AppColors";

PageContent.propTypes = {};

function PageContent(props) {
  return (
    <div
      className="d-flex flex-column flex-root min-vh-100"
      style={{ backgroundColor: AppColors.AppPageColor }}
    >
      {/* begin::Page */}
      <div className="d-flex flex-row flex-column-fluid page">
        {/* begin::Wrapper */}
        <div
          className="d-flex flex-column flex-row-fluid wrapper "
          id="kt_wrapper"
        >
          {/* begin::Header */}
          <Header
            backgroundImage={props.backgroundImage}
            headerTitle={props.headerTitle}
            random={true}
          />
          {/* end::Header */}

          {/* begin::Content */}

          <div
            className="content d-flex flex-column flex-column-fluid mx-5 "
            style={{ zIndex: "1" }}
          >
            <div className="d-flex flex-column-fluid">
              <div className="container-fluid px-0">{props.children}</div>
            </div>
          </div>
          {/* end::Content */}

          {/* begin::Footer */}
          <Footer />
          {/* end::Footer */}
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Page */}
    </div>
  );
}

export default PageContent;
