import axiosClient from "./axiosClient";

const providerApi = {
  getProviders: () => {
    const url = `/providers`;
    return axiosClient.get(url);
  },

  getAccountOfProviders: (maNhaCungCap) => {
    const url = `/providers/${maNhaCungCap}/accounts`;
    return axiosClient.get(url);
  },

  getDevicesOfAccount: (accountId, params) => {
    const url = `/providers/accounts/${accountId}/devices`;
    return axiosClient.get(url, { params });
  },

  addProvider: (params) => {
    const { Ten, Email, LienHe, DiaChi, MaNhaCungCap } = params;
    const url = `/providers/create`;
    return axiosClient.put(url, {
      Ten: Ten,
      Email: Email,
      LienHe: LienHe,
      DiaChi: DiaChi,
      MaNhaCungCap: MaNhaCungCap,
    });
  },

  deleteProvider: (MaNhaCungCap) => {
    const url = `/providers/delete`;
    return axiosClient.delete(url, { data: { MaNhaCungCap: MaNhaCungCap } });
  },

  modifyProvider: (params) => {
    const { id, Ten, Email, LienHe, DiaChi, MaNhaCungCap } = params;
    const url = `/providers/update`;
    return axiosClient.post(url, {
      id: id,
      Ten: Ten,
      Email: Email,
      LienHe: LienHe,
      DiaChi: DiaChi,
      MaNhaCungCap: MaNhaCungCap,
    });
  },

  addAccount: (params) => {
    const { providerId, serverUrl, account, password, radioStationId, areaId } =
      params;
    const url = `/providers/accounts/add`;
    return axiosClient.put(url, {
      serverUrl: serverUrl,
      account: account,
      password: password,
      radioStationId: radioStationId,
      providerId: providerId,
      areaId: areaId,
    });
  },

  deleteAccount: (id) => {
    const url = `/providers/accounts/delete`;
    return axiosClient.delete(url, { data: { ids: `[${id.toString()}]` } });
  },

  modifyAccount: (params) => {
    const { id, serverUrl, account, password } = params;
    const url = `/providers/accounts/update`;
    return axiosClient.post(url, {
      id: id,
      serverUrl: serverUrl,
      account: account,
      password: password,
    });
  },
};

export default providerApi;
