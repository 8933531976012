import React, { useEffect, useRef, useState } from "react";
import "./Sidebar.scss";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppIcons } from "general/constants/AppResource";
import AppData from "general/constants/AppData";
import { useTranslation } from "react-i18next";
import Utils from "general/utils/Utils";
import Global from "general/Global";
import { setActivePath } from "../../libs/storage/appSlice";

let KTLayoutAside = require("assets/plugins/aside/aside");
let KTLayoutAsideMenu = require("assets/plugins/aside/aside-menu");
let KTLayoutAsideToggle = require("assets/plugins/aside/aside-toggle");
let KTOffcanvas = require("assets/plugins/ktoffcanvas");

Sidebar.propTypes = {};

function Sidebar(props) {
  // MARK: --- Params ---
  const activePath = useSelector((state) => state.app.activePath);
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = history;
  const { t } = useTranslation();
  const pathname = location.pathname;

  const refOffCanvasAside = useRef(null);

  useEffect(() => {
    // Init Aside Menu
    if (typeof KTLayoutAside !== "undefined" && !window.KTLayoutAsideInited) {
      KTLayoutAside.init("kt_aside");
    }

    // Init Aside Toggle
    if (
      typeof KTLayoutAsideToggle !== "undefined" &&
      !window.KTLayoutAsideToggleInited
    ) {
      KTLayoutAsideToggle.init("kt_aside_toggle");
    }

    // Init Aside Menu
    if (
      typeof KTLayoutAsideMenu !== "undefined" &&
      !window.KTLayoutAsideMenuInited
    ) {
      KTLayoutAsideMenu.init("kt_aside_menu");
    }

    // Init Off Canvas
    if (typeof KTOffcanvas !== "undefined") {
      const offCanvasAside = new KTOffcanvas("kt_aside", {
        baseClass: "aside",
        overlay: true,
        toggleBy: {
          target: "kt_aside_mobile_toggle",
          // state: 'mobile-toggle-active'
        },
      });
      refOffCanvasAside.current = offCanvasAside;
    }
  }, []);

  useEffect(() => {
    dispatch(setActivePath(location.pathname));
  }, []);

  return (
    <div className="aside aside-left aside-fixed" id="kt_aside">
      {/* begin::Aside Brand */}
      <div className="aside-brand h-80px px-7 mt-2 flex-shrink-0">
        {/* begin::Logo */}
        <Link className="aside-logo " to="/">
          <div className="d-flex align-items-center align-items-center">
            <img
              className="max-h-40px mr-3"
              src={AppIcons.icLogoNew}
              alt="Logo"
            />
            <div>
              <p
                style={{ fontSize: "14px", fontWeight: "700" }}
                className="text-white"
              >
                HỆ THỐNG THÔNG TIN NGUỒN
              </p>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#FFD600",
                }}
              >
                HẢI PHÒNG
              </p>
            </div>
          </div>
        </Link>
        {/* end::Logo */}
        {/* begin::Toggle */}
        {/* <button
          className="aside-toggle btn btn-sm btn-icon-white px-0"
          id="kt_aside_toggle"
        >
          <span className="svg-icon svg-icon svg-icon-xl">
            <img src={AppIcons.icMenuHandle} alt="Handle" />
          </span>
        </button> */}
        {/* end::Toggle */}
      </div>
      {/* end::Aside Brand */}
      {/* begin::Aside Menu */}
      <div
        id="kt_aside_menu"
        className="aside-menu my-3"
        // data-menu-vertical="1"
        data-menu-scroll="1"
      >
        {/* begin::Menu Nav */}
        <ul className="menu-nav">
          {AppData.sidebarMenuItems.map((item, index) => {
            const { type, text, icon, path, subMenu } = item;
            const isActive = location.pathname.includes(path);
            const hasSubMenu = subMenu !== undefined;

            if (type === "item") {
              return (
                <li
                  key={index}
                  className={`menu-item ${isActive ? "menu-item-active" : ""} ${
                    hasSubMenu ? "menu-item-submenu" : ""
                  } ${pathname.includes(item.path) && " menu-item-open"}`} // menu-item-open
                >
                  <Link
                    to={path}
                    className={`align-items-center menu-link ${
                      hasSubMenu ? "menu-toggle" : ""
                    }`}
                    onClick={() => {
                      dispatch(setActivePath(path));

                      if (path === "/research") {
                        Global.gNeedToRefreshArticleList = true;
                      }

                      if (!hasSubMenu && refOffCanvasAside.current) {
                        refOffCanvasAside.current.hide();
                      }
                    }}
                  >
                    <span className="svg-icon menu-icon">
                      <i className={`${icon} text-white`}></i>
                    </span>
                    <span className="menu-text">{t(text)}</span>
                    {hasSubMenu && <i className="menu-arrow q-arrow" />}
                  </Link>
                  {hasSubMenu && (
                    <div className="menu-submenu">
                      <ul className="menu-subnav">
                        {subMenu.map((subItem, subIndex) => {
                          const isSubMenuActive = location.pathname.includes(
                            subItem.path
                          );
                          return (
                            <li
                              key={subIndex}
                              className={`menu-item ${
                                isSubMenuActive ? "menu-item-active" : ""
                              }`}
                            >
                              <Link
                                className="menu-link"
                                style={{ borderRadius: 0 }}
                                to={subItem.path}
                                onClick={() => {
                                  if (
                                    subItem.path ===
                                    "/knowledge-center/articles"
                                  ) {
                                    Global.gNeedToRefreshArticleList = true;
                                  }
                                  if (refOffCanvasAside.current) {
                                    refOffCanvasAside.current.hide();
                                  }
                                }}
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span></span>
                                </i>
                                <span className="menu-text">
                                  {t(subItem.text)}
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </li>
              );
            }
            if (type === "section") {
              return (
                <li key={index} className="menu-section">
                  <h4 className="menu-text">{t(text)}</h4>
                  <i className={`menu-icon ${icon}`}></i>
                </li>
              );
            }
          })}
        </ul>
        {/* end::Menu Nav */}
      </div>
      {/* end::Aside Menu */}
    </div>
  );
}

export default Sidebar;
