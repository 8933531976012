import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AppPage from "components/AppPage";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import AppCard from "components/AppCard";
import NavBar from "components/NavBar";
import { AppImages } from "general/constants/AppResource";
import { useDispatch, useSelector } from "react-redux";
import BingMap from "pages/DeviceMap/components/BingMap";
import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import BaseSearchBarSuggestion from "general/custom-fields/BaseSearchBarSuggestion";
import Utils, { findAreaById } from "general/utils/Utils";
import SelectProviderDropdown from "pages/ProviderManager/components/SelectProviderDropdown";
import { useResizeDetector } from "react-resize-detector";
import { SelectSort } from "pages/Category/components/SelectSort";
import ipRadioApi from "api/ipradioApi";
import digitalsignageApi from "api/digitalsignageApi";
import SelectAreaV2 from "components/SelectAreaV2/SelectAreaV2";

DeviceMapScreen.propTypes = {};

DeviceMapScreen.defaultProps = {};

const MAX_LONGITUDE = 109.4;
const MIN_LONGITUDE = 102.1;
const MAX_LATITUDE = 23.39;
const MIN_LATITUDE = 8.59;

let allSpeakers = [];
let digitalSignage = [];

function DeviceMapScreen(props) {
  // MARK: --- Params ---
  const {} = props;
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.current);
  const dispatch = useDispatch();
  const [selectedArea, setSelectedArea] = useState();
  const [selectedSpeaker, setSelectedSpeaker] = useState();
  const [devices, setDevices] = useState([]);
  const [allDevices, setAllDevices] = useState([]);
  const bingMapsRef = useRef(null);
  const deviceOption = [
    { value: "speaker", display: t("RadioDevice") },
    { value: "bulletinBoard", display: t("BulletinBoard") },
  ];
  const [currentDeviceOption, setCurrentDeviceOption] = useState("speaker");
  const { width, height, ref } = useResizeDetector();

  // MARK: --- Functions ---

  // MARK: --- Hooks ---
  const allAreas = useSelector((state) => state.area.allAreas);
  const listArea = useSelector((state) => state.area.listArea);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.MaKhuVuc &&
      selectedArea?.MaKhuVuc != currentUser.MaKhuVuc
    ) {
      setSelectedArea(
        findAreaById(currentUser.MaKhuVuc ? currentUser.MaKhuVuc : -1)
      );
    }
  }, [currentUser, allAreas]);

  const mapState = (state) => {
    switch (state) {
      case "0":
        return "RUNNING";
      case "1":
        return "IDLE";
      case "2":
        return "IDLE";
      default:
        break;
    }
  };

  const mapCumLoaData = (cumloa) => {
    if (!cumloa) {
      return cumloa;
    }
    return {
      ...cumloa,
      type: "speaker",
      latitude: cumloa?.ViDo,
      longitude: cumloa?.KinhDo,
      deviceId: cumloa?.CumLoaID,
      connection:
        !cumloa?.TrangThaiKetNoi || cumloa?.TrangThaiKetNoi == "Mấtkếtnối"
          ? "OFFLINE"
          : cumloa?.TrangThaiKetNoi?.toUpperCase(),
      state: mapState(cumloa?.TrangThaiHoatDong),
      name: cumloa?.TenThietBi,
    };
  };

  const mapBangTinDienTuData = (bangTin) => {
    if (!bangTin) {
      return bangTin;
    }
    return {
      ...bangTin,
      type: "bulletinBoard",
      latitude: bangTin?.ViDo,
      longitude: bangTin?.KinhDo,
      deviceId: bangTin?.BangTinDienTuID,
      connection:
        !bangTin?.TrangThaiKetNoi || bangTin?.TrangThaiKetNoi == "Mấtkếtnối"
          ? "OFFLINE"
          : bangTin?.TrangThaiKetNoi?.toUpperCase()?.slice(
              0,
              bangTin?.TrangThaiKetNoi?.indexOf(",")
            ),
      state: mapState(bangTin?.TrangThai),
      name: bangTin?.TenThietBi,
    };
  };

  const handleUpdateDevice = async (MaKhuVuc) => {
    let res = await ipRadioApi.getDevicesInArea({
      MaKhuVuc: MaKhuVuc || "",
      limit: -1,
    });

    allSpeakers =
      res.data?.NoiDung?.DanhSachCumLoa?.filter(
        (item) =>
          !Utils.isObjectEmpty(item.ViDo) &&
          !Utils.isObjectEmpty(item.KinhDo) &&
          item.ViDo > MIN_LATITUDE &&
          item.ViDo < MAX_LATITUDE &&
          item.KinhDo > MIN_LONGITUDE &&
          item.KinhDo < MAX_LONGITUDE
      ).map((item) => mapCumLoaData(item)) || [];

    res = await digitalsignageApi.getDevicesInArea({
      MaKhuVuc: MaKhuVuc || "",
      limit: -1,
    });
    digitalSignage =
      res.data?.NoiDung?.danhSachBangTin
        ?.filter(
          (item) =>
            !Utils.isObjectEmpty(item.ViDo) &&
            !Utils.isObjectEmpty(item.KinhDo) &&
            item.ViDo > MIN_LATITUDE &&
            item.ViDo < MAX_LATITUDE &&
            item.KinhDo > MIN_LONGITUDE &&
            item.KinhDo < MAX_LONGITUDE
        )
        .map((item) => mapBangTinDienTuData(item)) || [];
    setAllDevices([...allSpeakers, ...digitalSignage]);
    setDevices(allDevices?.filter((item) => item.type == currentDeviceOption));
  };
  useEffect(async () => {
    dispatch(thunkGetAreas());

    if (currentUser) {
      handleUpdateDevice(currentUser?.MaKhuVuc || "");
    }
  }, [currentUser]);

  useEffect(async () => {
    let tempDevices = [...allDevices];

    if (currentDeviceOption) {
      tempDevices = tempDevices?.filter(
        (item) => item.type == currentDeviceOption
      );
    }
    setDevices(tempDevices);
  }, [currentDeviceOption, allDevices]);

  useEffect(async () => {
    if (selectedArea) {
      handleUpdateDevice(selectedArea?.MaKhuVuc || "");
    }
  }, [selectedArea]);

  return (
    <>
      <AppPage
        backgroundImage={AppImages.imgBackground4}
        headerTitle={t("appName")}
      >
        <NavBar navBarTitle={t("appName")}></NavBar>
        <div ref={ref} className="d-flex flex-column flex-column-fluid my-2">
          <AppCard
            title={t("DeviceMap")}
            buttonText=""
            clickAdd={() => {
              setModalCreateAreaShowing(true);
            }}
            additionalHeader={
              <>
                <div className="col-12 col-md-3 max-w-md-300px px-smaller-md-0 pl-md-0">
                  <BaseSearchBarSuggestion
                    className="w-100"
                    placeholder="Tìm kiếm"
                    listItems={devices}
                    renderItem={(item) => `[${item.deviceId}] ${item.name}`}
                    filterFields={["deviceId", "name"]}
                    onSelect={(item) => {
                      if (item?.id == selectedSpeaker?.id)
                        bingMapsRef.current.zoomSelectedSpeaker();
                      setSelectedSpeaker(item);
                    }}
                  />
                </div>
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0">
                  <p
                    className={`display-none ${
                      width > 792 && "display-md-block"
                    } font-weight-bold mr-3 w-md-max-content`}
                    style={{ color: "#4A5677" }}
                  >
                    {t("Display")}
                  </p>
                  <SelectAreaV2
                    onSelect={(area) => {
                      console.log("On select area", area);
                      if (area == selectedArea?.MaKhuVuc)
                        bingMapsRef.current.zoomSelectedArea();
                      setSelectedArea(
                        listArea?.find((ar) => ar?.MaKhuVuc == area)
                      );
                    }}
                    rootAreaId={currentUser?.MaKhuVuc}
                    selectedArea={selectedArea}
                    className="flex-grow-1"
                  />
                </div>
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0 pr-md-0">
                  <p
                    className={`display-none ${
                      width > 792 && "display-md-block"
                    } font-weight-bold mr-3 w-md-max-content`}
                    style={{ color: "#4A5677" }}
                  >
                    {t("Devices")}
                  </p>
                  <SelectSort
                    className="flex-grow-1"
                    selections={deviceOption}
                    disable={false}
                    currentValue={
                      _.find(deviceOption, { value: currentDeviceOption })
                        ?.display ?? t("RadioDevice")
                    }
                    onSelect={(item) => {
                      setCurrentDeviceOption(item.value);
                    }}
                  />
                </div>
              </>
            }
          >
            <BingMap
              ref={bingMapsRef}
              areaId={selectedArea?.MaKhuVuc}
              selectedSpeaker={selectedSpeaker}
              allDevices={devices}
            />
          </AppCard>
        </div>
      </AppPage>
    </>
  );
}

export default DeviceMapScreen;
