import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classnames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import styles from "./Feedback.module.scss";
import AppPage from "components/AppPage";
import { AppImages } from "general/constants/AppResource";
import NavBar from "components/NavBar";
import AppCard from "components/AppCard";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import SelectArea from "components/SelectArea/SelectArea";
import DataTable from "components/Table";
import AppPagination from "components/AppPagination";
import fireIcon from "../../../assets/icons/fire.png";
import mediumIcon from "../../../assets/icons/medium.png";
import ToastHelper from "general/helpers/ToastHelper";
import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import feedbackApi from "api/feedbackApi";
import { SelectSort } from "pages/Category/components/SelectSort";
import {
  setLimit,
  setOrderBy,
  setOrderType,
  setPage,
  setStatus,
  thunkGetFeedback,
} from "../feedbackSlice";
import { ModalFeedback } from "../conponents/Modal";
import ModalConfirmShare from "../conponents/ModalConfirmShare";
import { findAreaById } from "general/utils/Utils";

const cx = classnames.bind(styles);

function Feedback() {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.current);
  const areasByUser = useSelector((state) => state.area.allAreas);
  const dispatch = useDispatch();
  const {
    page,
    limit,
    total,
    status,
    feedbacks,
    orderBy,
    orderType,
    loadingState,
  } = useSelector((state) => state.feedback);
  const [inputSearch, setInputSearch] = useState("");
  const [currentArea, setCurrentArea] = useState();
  const [currentFeedback, setCurrrentFeedback] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [sortByCode, setSortByCode] = useState(false);
  const [sortByContent, setSortByContent] = useState(false);
  const [sortByPriority, setSortByPriority] = useState(false);
  const [sortByStatus, setSortByStatus] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const reload = () => {
    dispatch(
      thunkGetFeedback({
        filters: {
          areaId: currentArea?.MakhuVuc,
          status: status == "all" ? undefined : status,
        },
        order: [orderBy, orderType],
        query: inputSearch,
        page: page,
        limit: limit,
      })
    );
  };
  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser?.MakhuVuc &&
      currentArea?.MakhuVuc != currentUser?.MakhuVuc
    ) {
      setCurrentArea(
        findAreaById(currentUser?.MakhuVuc ? currentUser.MakhuVuc : -1)
      );
    }
  }, [currentUser, allAreas]);

  const handleSendFeedback = async (feedback) => {
    const response = await feedbackApi.answerFeedback({
      answer: feedback,
      feedbackId: currentFeedback.id,
    });
    if (response.data.result == "success") {
      ToastHelper.showSuccess(t("Answered"));
      setShowModal(false);
      reload();
    }
  };

  useEffect(() => {
    reload();
  }, [status, page, limit, inputSearch, currentArea, orderBy, orderType]);

  useEffect(() => {
    if (areasByUser.length == 0) {
      dispatch(thunkGetAreas());
    }
  }, []);

  const selections = [
    { id: 0, value: "all", display: t("All") },
    { id: 1, value: "pending", display: t("Pending") },
    { id: 2, value: "processed", display: t("Processed") },
  ];

  return (
    <>
      <AppPage
        className={cx("container")}
        backgroundImage={AppImages.imgBackground15}
        headerTitle={t("Feedback").toUpperCase()}
      >
        <NavBar navBarTitle={t("Feedback").toUpperCase()}></NavBar>
        <div className="RadioStationManager d-flex flex-column flex-column-fluid my-2">
          <AppCard
            className={cx("container")}
            title={t("FeedbackFromCitizen")}
            buttonText=""
            additionalHeader={
              <>
                <div className="col-12 col-md-3 max-w-md-300px px-smaller-md-0 pl-md-0">
                  <BaseSearchBar
                    name="searchBar"
                    placeholder={t("Search")}
                    onSubmit={setInputSearch}
                    className="w-100"
                  />
                </div>
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0">
                  <p
                    className="display-none display-md-block font-weight-bold mr-3 w-md-max-content"
                    style={{ color: "#4A5677" }}
                  >
                    {t("Display")}
                  </p>
                  <SelectArea
                    onSelect={(area) => {
                      setSortByCode(false);
                      setSortByContent(false);
                      setSortByPriority(false);
                      setSortByStatus(false);
                      if (area.MakhuVuc != currentArea?.MakhuVuc) {
                        setCurrentArea(area);
                      }
                    }}
                    rootAreaId={currentUser.MakhuVuc}
                    selectedArea={currentArea}
                    className="flex-grow-1"
                  />
                </div>
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0 pr-md-0">
                  <p
                    className="display-none display-md-block font-weight-bold mr-3 w-md-max-content"
                    style={{ color: "#4A5677" }}
                  >
                    {t("Status")}
                  </p>
                  <SelectSort
                    className="flex-grow-1"
                    selections={selections}
                    disable={false}
                    currentValue={
                      status == "all"
                        ? t("All")
                        : status == "pending"
                        ? t("Pending")
                        : t("Processed")
                    }
                    onSelect={(item) => {
                      if (orderBy != "id") {
                        dispatch(setOrderBy("id"));
                      }
                      setSortByCode(false);
                      setSortByContent(false);
                      setSortByPriority(false);
                      setSortByStatus(false);
                      if (item.value != status) {
                        dispatch(setStatus(item.value));
                      }
                    }}
                  />
                </div>
              </>
            }
          >
            <DataTable
              cols={[14, 40, 8, 8, 16, 12]}
              className={cx("table")}
              headItems={[
                <div
                  className="d-flex flex-row"
                  onClick={() => {
                    setSortByCode(true);
                    setSortByContent(false);
                    setSortByPriority(false);
                    setSortByStatus(false);
                    dispatch(setOrderBy("feedbackCode"));
                    if (orderType == "asc") {
                      dispatch(setOrderType("desc"));
                    } else {
                      dispatch(setOrderType("asc"));
                    }
                  }}
                >
                  <span className={cx("list")}>{t("List")}</span>
                  {sortByCode && (
                    <FontAwesomeIcon
                      className={cx("icon-sort")}
                      icon={orderType == "asc" ? faCaretUp : faCaretDown}
                    />
                  )}
                </div>,
                <div
                  className={cx("row")}
                  onClick={() => {
                    setSortByCode(false);
                    setSortByContent(true);
                    setSortByPriority(false);
                    setSortByStatus(false);
                    dispatch(setOrderBy("content"));
                    if (orderType == "asc") {
                      dispatch(setOrderType("desc"));
                    } else {
                      dispatch(setOrderType("asc"));
                    }
                  }}
                >
                  {t("Content")}
                  {sortByContent && (
                    <FontAwesomeIcon
                      className={cx("icon-sort")}
                      icon={orderType == "asc" ? faCaretUp : faCaretDown}
                    />
                  )}
                </div>,
                <div
                  className={cx("row")}
                  onClick={() => {
                    setSortByCode(false);
                    setSortByContent(false);
                    setSortByPriority(true);
                    setSortByStatus(false);
                    dispatch(setOrderBy("priority"));
                    if (orderType == "asc") {
                      dispatch(setOrderType("desc"));
                    } else {
                      dispatch(setOrderType("asc"));
                    }
                  }}
                >
                  {t("Priority")}
                  {sortByPriority && (
                    <FontAwesomeIcon
                      className={cx("icon-sort")}
                      icon={orderType == "asc" ? faCaretUp : faCaretDown}
                    />
                  )}
                </div>,
                <div
                  className={cx("row")}
                  onClick={() => {
                    setSortByCode(false);
                    setSortByContent(false);
                    setSortByPriority(false);
                    setSortByStatus(true);
                    dispatch(setOrderBy("status"));
                    if (orderType == "asc") {
                      dispatch(setOrderType("desc"));
                    } else {
                      dispatch(setOrderType("asc"));
                    }
                  }}
                >
                  {t("Status")}
                  {sortByStatus && (
                    <FontAwesomeIcon
                      className={cx("icon-sort")}
                      icon={orderType == "asc" ? faCaretUp : faCaretDown}
                    />
                  )}
                </div>,
                t("Area"),
                t("Manage"),
              ]}
              dataItems={feedbacks.map((item) => ({
                data: [
                  {
                    comp: (
                      <div
                        key={item.id}
                        className="d-flex flex-row custom-row-checkbox cursor-pointer"
                        onClick={() => {
                          setCurrrentFeedback(item);
                          setShowModal(true);
                        }}
                      >
                        <span className={cx("id")}>{item.feedbackCode}</span>
                      </div>
                    ),
                  },
                  {
                    comp: (
                      <div
                        className={cx("content")}
                        onClick={() => {
                          setCurrrentFeedback(item);
                          setShowModal(true);
                        }}
                      >
                        {item.content}
                      </div>
                    ),
                  },
                  {
                    comp: (
                      <Priority
                        type={item.priority}
                        onClick={() => {
                          setCurrrentFeedback(item);
                          setShowModal(true);
                        }}
                      />
                    ),
                  },
                  {
                    comp: (
                      <Status
                        type={item.status}
                        onClick={() => {
                          setCurrrentFeedback(item);
                          setShowModal(true);
                        }}
                      />
                    ),
                  },
                  {
                    comp: (
                      <span
                        className={cx("area")}
                        onClick={() => {
                          setCurrrentFeedback(item);
                          setShowModal(true);
                        }}
                      >
                        {item.area.name}
                      </span>
                    ),
                  },
                  {
                    comp: (
                      <div className="d-flex flex-row">
                        <button
                          className={cx("answer")}
                          style={{ marginRight: "5px" }}
                          onClick={() => {
                            setCurrrentFeedback(item);
                            setShowModal(true);
                          }}
                        >
                          {t("Answer").toLocaleUpperCase()}
                        </button>
                        <button
                          className={cx("share")}
                          onClick={() => {
                            setCurrrentFeedback(item);
                            setShowModalConfirm(true);
                          }}
                        >
                          {t("Chia sẻ").toLocaleUpperCase()}
                        </button>
                      </div>
                    ),
                  },
                ],
              }))}
              onRefresh={() => reload()}
              isLoading={loadingState}
            />
            <AppPagination
              className="bg-white"
              total={total}
              curStep={limit}
              curPage={page + 1}
              setPage={(page) => dispatch(setPage(page - 1))}
              setStep={(step) => dispatch(setLimit(step))}
            />
          </AppCard>
        </div>
      </AppPage>
      <ModalFeedback
        show={showModal}
        onCancel={() => setShowModal(false)}
        handleSendFeedback={handleSendFeedback}
        currentFeedback={currentFeedback}
        onHide={() => setShowModal(false)}
      />
      <ModalConfirmShare
        show={showModalConfirm}
        onClose={() => setShowModalConfirm(false)}
        onSubmit={() => {
          setShowModalConfirm(true);
        }}
        text="Chia sẻ trả lời phản ánh với Hệ thống thông tin nguồn trung ương"
      />
    </>
  );
}

export default Feedback;

function Priority({ type = "high", onClick }) {
  const { t } = useTranslation();

  return (
    <div
      className={cx("wrapper")}
      onClick={onClick}
      style={{
        backgroundColor:
          type == "high" ? "rgba(230, 65, 53, 0.1)" : "rgba(81, 128, 251, 0.1)",
      }}
    >
      <img
        className={cx("icon")}
        style={type != "high" ? { width: 13, height: 13 } : {}}
        src={type == "high" ? fireIcon : mediumIcon}
      />
      <span
        className={cx("label")}
        style={{ color: type == "high" ? "#E64135" : "#5180FB" }}
      >
        {type == "high" ? t("High") : t("Medium")}
      </span>
    </div>
  );
}

export { Priority };

function Status({ type = "processed", onClick }) {
  const { t } = useTranslation();

  return (
    <div
      className={cx("wrapper")}
      onClick={onClick}
      style={{
        backgroundColor:
          type == "processed"
            ? "rgba(32, 161, 68, 0.1)"
            : "rgba(229, 110, 25, 0.1)",
      }}
    >
      <span
        className={cx("label")}
        style={{ color: type == "processed" ? "#20A144" : "#E56E19" }}
      >
        {type == "processed" ? t("Processed") : t("Pending")}
      </span>
    </div>
  );
}
