import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Modal } from "react-bootstrap";
import { Account, Area } from "../../../../general/types/index.d";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import BaseTextField from "../../../../general/custom-fields/BaseTextField";
import SelectArea from "../../../../components/SelectArea/SelectArea";
import { useDispatch, useSelector } from "react-redux";
import KeenChangeAvatar from "../../../../components/Keen/KeenChangeAvatar";
import KeenSelectOption from "../../../../components/Keen/KeenSelectOption";
import ReactSwitch from "react-switch";
import userApi from "../../../../api/userApi";
import ToastHelper from "../../../../general/helpers/ToastHelper";
import Utils, { findNode } from "general/utils/Utils";
import LoadingOverlay from "components/LoadingOverlay";
import CheckBox from "components/CheckBox";
import "./styles.scss";
import CardDetail from "components/CardDetail";
import CardDetailHaveButton from "components/CardDetailHaveButton";
import AccountCard from "components/AccountCard";
import SelectProviderDropdown from "pages/ProviderManager/components/SelectProviderDropdown";
import TreeItemDropdown from "pages/AccountManager/components/TreeItemDropdown";

/**
 * @description depend on `formik` and `yup` lib
 *
 * @param {Object} props
 * @param {string=} props.className wrapper component class name
 * @param {boolean=} props.show is show or not
 * @param {(success?:boolean)=>void=} props.onDismiss onDismiss callback
 * @param {()=>void=} props.handleDelete when press delete button
 * @param {Account} props.account account
 */
export default function ModalEditAccount(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  /**
   * @type {Area[]}
   */
  const allAreas = useSelector((state) => state.area.allAreas);
  /**
   * @type {Account}
   */
  const currentUser = useSelector((state) => state.auth.current);
  let { className, show, onDismiss, account, handleDelete } = props;
  let isEditing = !!account;
  const [showLoading, setShowLoading] = useState(false);
  const passwordMaxLength = process.env.REACT_APP_PASSWORD_MAX_LENGTH;
  const passwordMinLength = process.env.REACT_APP_PASSWORD_MIN_LENGTH;
  const passwordHaveCapitalLetter =
    process.env.REACT_APP_PASSWORD_CAPITAL_LETTER;
  const passwordHaveNonCapitalLetter =
    process.env.REACT_APP_PASSWORD_NON_CAPITAL_LETTER;
  const passwordHaveNumber = process.env.REACT_APP_PASSWORD_NUMBER_LETTER;
  const [passwordRequirement, setPasswordRequirement] = useState(null);
  let initialPasswordErrorText =
    "Mật khẩu gồm ít nhất " + passwordMinLength + " kí tự";
  const [passwordRequirementErrorText, setPasswordRequirementErrorText] =
    useState(initialPasswordErrorText);
  let regExp = new RegExp(/^/);
  const accountStateOption = [
    { value: "HoatDong", text: t("Active") },
    { value: "NgungHoatDong", text: t("Blocked") },
  ];

  useEffect(() => {
    if (passwordHaveCapitalLetter == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[A-Z])/.source);
      initialPasswordErrorText =
        initialPasswordErrorText + ", 1 kí tự viết hoa";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if (passwordHaveNonCapitalLetter == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[a-z])/.source);
      initialPasswordErrorText =
        initialPasswordErrorText + ", 1 kí tự viết thường";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if (passwordHaveNumber == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[0-9])/.source);
      initialPasswordErrorText = initialPasswordErrorText + ", 1 chữ số";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    setPasswordRequirement(regExp);
  }, []);

  const showIndicating = () => {
    setShowLoading(true);
  };

  const hideIndicating = () => {
    setShowLoading(false);
  };

  /**
   *
   * @param {boolean=} success
   */
  const handleClose = (success) => {
    _.isFunction(onDismiss) && onDismiss(success);
    hideIndicating();
    setTimeout(formik.resetForm);
    setIsHiddenPassword(true);
  };

  let passwordYup = isEditing
    ? {}
    : {
        password: Yup.string()
          .required(t("enterPass"))
          .min(passwordMinLength, passwordRequirementErrorText)
          .max(passwordMaxLength, passwordRequirementErrorText)
          .matches(passwordRequirement, passwordRequirementErrorText)
          .matches(/^(?!.*[\s])/, "Có khoảng trắng trong mật khẩu"),
      };

  const validationSchema = Yup.object().shape({
    TaiKhoan: Yup.string().required(t("Required")),
    // ...passwordYup,
    ttsLimit: Yup.number().min(0, t("Invalid")).nullable().notRequired(),
    ttsCount: Yup.number().min(0, t("Invalid")).nullable().notRequired(),
    // MaKhuVuc: Yup.number().required(t("Required")),
    Email: Yup.string().email(t("InvalidEmail")).nullable(),
    SDT: Yup.string()
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, {
        message: t("InvalidPhone"),
      })
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      ...account,
      MaKhuVuc: account?.MaKhuVuc ?? account?.area?.id,
      ttsLimit: account?.vbee?.monthLimitCharacters,
      ttsCount: account?.vbee?.monthUsedCharacters,
      vbeeUsername: account?.vbee?.username,
      vbeeApplicationId: account?.vbee?.applicationId,
      vbeeDictionaryId: account?.vbee?.dictionaryId,
      vbeeToken: account?.vbee?.token,
      PheDuyetBanTin: !!account?.PheDuyetBanTin,
      QuanLyBanTin: !!account?.QuanLyBanTin,
      QuanLyKhuVuc: !!account?.QuanLyKhuVuc,
      QuanLyLichPhat: !!account?.QuanLyLichPhat,
      PheDuyetLichPhat: !!account?.PheDuyetLichPhat,
      QuanLyNhaCungCap: !!account?.QuanLyNhaCungCap,
      managerAccountProvider: !!account?.managerAccountProvider,
      QuanLyTaiKhoan: !!account?.QuanLyTaiKhoan,
      managerProvider: !!account?.managerProvider,
      QuanLyThietBi: !!account?.QuanLyThietBi,
      AnhDaiDienFile: null,
      password: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("====================================");
      console.log(values);
      console.log("====================================");

      try {
        if (!values.MaKhuVuc) {
          hideIndicating();
          return ToastHelper.showError(t("MissingArea"));
        }

        if (values.vbee)
          values["vbee"] = JSON.stringify({
            monthLimitCharacters: values.ttsLimit,
            monthUsedCharacters: values.ttsCount,
            // username: values.username,
            applicationId: values.vbeeApplicationId,
            // dictionaryId: values.vbeeDictionaryId,
            token: values.vbeeToken,
          });

        // if (values.password) {
        //   values["password"] = await Utils.hash(values.password);
        // }

        showIndicating();

        if (!isEditing) {
          let { MoTa } = (
            await userApi.createUser({
              ...values,
              // password: values.password
              //   ? await Utils.hash(values.password)
              //   : values.password,
            })
          ).data;
          if (MoTa == "ThanhCong") {
            handleClose(true);
            ToastHelper.showSuccess(t("CreateUserSuccess"));
            dispatch(thunkSearchAccount());
          } else ToastHelper.showError(t("CreateUserFailed"));
        } else {
          values["userId"] = values.id;
          let { MoTa } = (
            await userApi.updateUserInfo({
              ...values,
              // password: values.password
              //   ? await Utils.hash(values.password)
              //   : values.password,
            })
          ).data;
          if (MoTa == "ThanhCong") {
            handleClose(true);
            ToastHelper.showSuccess(t("UpdateUserSuccess"));
          } else ToastHelper.showError(t("UpdateUserFailed"));
        }
      } catch (error) {
        // ToastHelper.showError(error.message);
      }
      hideIndicating();
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  const TitleWrapper = useCallback(
    ({ children, className }) => (
      <span className={`Bold_13 ${className}`}>{children}</span>
    ),
    []
  );

  const [isHiddenPassword, setIsHiddenPassword] = React.useState(true);

  return (
    // <div className={`${className}`}>
    <Modal
      scrollable={true}
      contentClassName="modal-height"
      size="xl"
      dialogClassName="modal"
      // centered
      className="font-weight-bold justify-content-center align-items-center"
      // aria-labelledby="contained-modal-title-vcenter"
      // dialogClassName="d-flex"
      show={!!show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header className="d-flex p-3">
        <Modal.Title className="justify-content-center align-items-center w-100 d-flex">
          <label className="font-weight-bold" style={{ color: "#4A5677" }}>
            {t("UserInfo")}
          </label>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className=" d-flex flex-column flex-column-fluid my-2 ">
          {/* <AccountCard
              // title={t("UserInfo")}
              clickSave={() => formik.handleSubmit()}
              // clickDelete={() => setModalDeleteAccount(true)}
              clickDelete={() => setModalOTP(true)}
              // blueButtonText={t("SaveChanges")}
              // whiteButtonText={t("Delete")}
              // loading={loading}
            > */}
          <form autoComplete="off" className="container-fluid bg-white w-100 ">
            <div className="row">
              <div className="col-lg-6">
                <CardDetail
                  title={t("Account")}
                  className="justify-content-center"
                  bottomComponent={
                    <div
                      className=" d-flex flex-column"
                      style={{ background: "white", padding: "10px" }}
                    >
                      <TitleWrapper className={"mb-2"}>
                        {t("ManageUserStatus")}
                      </TitleWrapper>

                      <SelectProviderDropdown
                        className="Normal_12 font-weight-bold"
                        name="TrangThai"
                        options={accountStateOption}
                        renderValue={(value) => (
                          <p
                            className={
                              formik.values.TrangThai == "blocked"
                                ? "text-danger"
                                : "text-primary"
                            }
                          >
                            {_.find(accountStateOption, { value: value })?.text}
                          </p>
                        )}
                        renderDropdownItem={(item) => (
                          <div
                            key={item.id}
                            className={`w-100 px-5 py-3 d-flex flex-row cursor-pointer font-weight-bold Regular_13 ${
                              formik.values.TrangThai == item.value &&
                              "bg-primary text-white"
                            }`}
                          >
                            <p className="">{item.text}</p>
                          </div>
                        )}
                        onItemClick={(item) => {
                          formik
                            .getFieldHelpers("TrangThai")
                            .setValue(item.value);
                        }}
                        fieldHelpers={formik.getFieldHelpers("TrangThai")}
                        fieldMeta={formik.getFieldMeta("TrangThai")}
                        fieldProps={formik.getFieldProps("TrangThai")}
                      />
                    </div>
                  }
                  // buttonTitle={t("ChangeProfilePicture")}
                >
                  <KeenChangeAvatar
                    className="justify-content-center align-items-center d-flex text-hover-danger"
                    imageClassName="w-200px h-200px"
                    disabledRevokeUrl
                    name="AnhDaiDien"
                    fieldProps={formik.getFieldProps("AnhDaiDien")}
                    fieldMeta={formik.getFieldMeta("AnhDaiDien")}
                    onFileSelected={([file]) => {
                      formik.getFieldHelpers("AnhDaiDienFile").setValue(file);
                    }}
                  />
                </CardDetail>

                <CardDetail title={t("Authority")}>
                  <div className="">
                    <div
                      className="d-flex flex-column pl-4 pr-4 pb-4"
                      style={{ background: "white" }}
                    >
                      <Formik
                        enableReinitialize
                        initialValues={{
                          ...formik.values,
                        }}
                      >
                        {({ values }) => (
                          <Form>
                            <div
                              className="d-flex flex-column font-weight-medium text-dark-75"
                              role="group"
                              aria-labelledby="checkbox-group"
                            >
                              {_.entries(values)
                                .filter(([key, value]) =>
                                  [
                                    "PheDuyetBanTin",
                                    "PheDuyetLichPhat",
                                    "QuanLyBanTin",
                                    "QuanLyKhuVuc",
                                    "QuanLyLichPhat",
                                    "QuanLyNhaCungCap",
                                    "QuanLyTaiKhoan",
                                    "QuanLyThietBi",
                                    {
                                      /* "managerAccountProvider",
                                    "managerProvider", */
                                    },
                                  ].includes(key)
                                )
                                .map(([key, value]) => {
                                  let disabled = !currentUser?.[key];
                                  return (
                                    <span
                                      key={key}
                                      style={{ color: "#4A5677" }}
                                      className={`align-content-centerNormal_13 mt-4 ${
                                        disabled ? "d-none" : "d-flex"
                                      }`}
                                    >
                                      <CheckBox
                                        onCheck={() => {
                                          formik
                                            .getFieldHelpers(key)
                                            .setValue(true);
                                        }}
                                        onUncheck={() => {
                                          formik
                                            .getFieldHelpers(key)
                                            .setValue(false);
                                        }}
                                        checked={!!value}
                                        key={key}
                                        className={"mr-4 mt-2"}
                                      />
                                      {t(`${key}`)}
                                    </span>
                                  );
                                })}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </CardDetail>
              </div>
              {/*  */}
              <div className="col-lg-6">
                <CardDetail
                  title={t("GeneralInformation")}
                  // onClick={receiveOTP}
                  // twoFactor={currentUser?.twoFactor}
                  // isLoading={loadingOTP}
                >
                  <div className="w-100 mx-5">
                    <div className="row">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("userName")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="TaiKhoan"
                          fieldProps={{
                            ...formik.getFieldProps("TaiKhoan"),
                            disabled: isEditing,
                          }}
                          fieldMeta={formik.getFieldMeta("TaiKhoan")}
                        />
                      </div>
                    </div>

                    {/* <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("password")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          type={isHiddenPassword ? "password" : "text"}
                          name="password"
                          fieldProps={{
                            ...formik.getFieldProps("password"),
                            disabled: isEditing,
                          }}
                          fieldMeta={formik.getFieldMeta("password")}
                        />
                      </div>
                      {!isEditing && (
                        <div
                          className={`password-toggle-container height-auto bg-transparent align-self-center ${
                            formik.errors.password ? "mr-20 mb-6" : "mr-15"
                          }`}
                          onClick={(e) => {
                            setIsHiddenPassword((hidden) => !hidden);
                          }}
                        >
                          {isHiddenPassword ? (
                            <i className="fas fa-eye "></i>
                          ) : (
                            <i className="fas fa-eye-slash "></i>
                          )}
                        </div>
                      )}
                    </div> */}

                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("FullName")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="Ten"
                          fieldProps={{
                            ...formik.getFieldProps("Ten"),
                          }}
                          fieldMeta={formik.getFieldMeta("Ten")}
                        />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("PhoneNumber")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="SDT"
                          fieldProps={{
                            ...formik.getFieldProps("SDT"),
                          }}
                          fieldMeta={formik.getFieldMeta("SDT")}
                          type="tel"
                        />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        Email
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="Email"
                          fieldProps={{
                            ...formik.getFieldProps("Email"),
                          }}
                          fieldMeta={formik.getFieldMeta("Email")}
                          type="email"
                        />
                      </div>
                    </div>
                    {/* <div className="row my-3">
                        <div className="col-4 pl-0">
                          <p className="labelText text-right">
                            {t("Position")}
                          </p>
                        </div>
                        <div className="col-8 pl-0">
                          <BaseTextField
                            className=""
                            name="position"
                            disabled={true}
                            fieldProps={{
                              ...formik.getFieldProps("position"),
                            }}
                            fieldMeta={formik.getFieldMeta("position")}
                          />
                        </div>
                      </div> */}
                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("Area")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        {/* <SelectArea
                          rootAreaId={currentUser?.MaKhuVuc}
                          selectedArea={_.forOwn(
                            {
                              ...allAreas.find(
                                (area) => area.id == formik.values.MaKhuVuc
                              ),
                            },
                            (value, key, object) => {
                              if (key == "id") object["AreaId"] = value;
                              else if (key == "name")
                                object["AreaName"] = value;
                            }
                          )}
                          onSelect={(area: Area) => {
                            if (area["AreaId"]) area["id"] = area["AreaId"];
                            formik.getFieldHelpers("MaKhuVuc").setValue(area.id);
                          }}
                        /> */}
                        <TreeItemDropdown
                          currentItem={findNode(
                            allAreas,
                            formik.getFieldProps("MaKhuVuc").value
                          )}
                          tree={allAreas}
                          additionalToggleClassname="text-left font-weight-bold"
                          initialToggleText="Chọn khu vực"
                          onChange={(item) => {
                            formik
                              .getFieldHelpers("MaKhuVuc")
                              .setValue(item.MaKhuVuc);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </CardDetail>
                <CardDetail title={t("SpeechSynthesis")}>
                  <div className="w-100 mx-5">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
                        {t("LimitedNumberOfCharactersForTheMonth")}
                      </div>
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
                        <BaseTextField
                          className=""
                          type="number"
                          name="ttsLimit"
                          fieldProps={{
                            ...formik.getFieldProps("ttsLimit"),
                            disabled: !formik.values.vbee,
                          }}
                          fieldMeta={formik.getFieldMeta("ttsLimit")}
                        />
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
                        {t("NumberOfCharactersUsedInTheMonth")}
                      </div>
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
                        <BaseTextField
                          className=""
                          type="number"
                          name="ttsCount"
                          fieldProps={{
                            ...formik.getFieldProps("ttsCount"),
                            disabled: !formik.values.vbee,
                          }}
                          fieldMeta={formik.getFieldMeta("ttsCount")}
                        />
                      </div>
                    </div>

                    <div className="bg-white d-flex flex-column">
                      <div className="d-flex flex-row justify-content-center pt-4 pb-4 align-items-center border mt-4">
                        <div className="labelInput mr-5 mb-0">
                          {t("VBEEAccountConfiguration")}
                        </div>
                        <ReactSwitch
                          onChange={(checked) => {
                            if (checked) {
                              formik.setValues((values) => ({
                                ...values,
                                ttsLimit: account?.vbee?.monthLimitCharacters,
                                ttsCount: account?.vbee?.monthUsedCharacters,
                                vbee: account?.vbee ?? {},
                              }));
                            } else {
                              formik.setValues((values) => ({
                                ...values,
                                ttsLimit: "",
                                ttsCount: "",
                                vbeeApplicationId: "",
                                vbeeDictionaryId: "",
                                vbeeUsername: "",
                                vbee: null,
                              }));
                            }
                          }}
                          checked={!!formik.values.vbee}
                          className="switch"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onColor="#3465E5"
                        />
                      </div>
                      <Collapse in={!!formik.values.vbee}>
                        <div style={{ backgroundColor: "#F6F7FB" }}>
                          <div className="row bg-light border border-top-0 mx-0 py-2">
                            <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
                              {t("Application ID")}
                            </div>
                            <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
                              <BaseTextField
                                className=""
                                fieldMeta={formik.getFieldMeta(
                                  "vbeeApplicationId"
                                )}
                                fieldProps={formik.getFieldProps(
                                  "vbeeApplicationId"
                                )}
                              />
                            </div>
                          </div>

                          <div className="row bg-light border border-top-0 mx-0 py-2">
                            <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
                              {t("VBEE Token")}
                            </div>
                            <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
                              <BaseTextField
                                className=""
                                fieldMeta={formik.getFieldMeta("vbeeToken")}
                                fieldProps={formik.getFieldProps("vbeeToken")}
                              />
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </CardDetail>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center flex-row p-3">
        <div className="row w-100">
          {isEditing && currentUser?.id != account?.id && (
            <div className="col-xl-4 col-lg-4">
              <a
                className="w-100 btn btn-danger font-weight-bold"
                onClick={handleDelete}
              >
                {t("DeleteUser")}
              </a>
            </div>
          )}

          <div
            className={
              isEditing && currentUser?.id != account?.id
                ? "col-xl-4 col-lg-4"
                : "col-xl-6 col-lg-6"
            }
          >
            <a
              className="w-100 btn btn-secondary font-weight-bold"
              onClick={() => handleClose()}
            >
              {t("Cancel")}
            </a>
          </div>

          <div
            className={
              isEditing && currentUser?.id != account?.id
                ? "col-xl-4 col-lg-4"
                : "col-xl-6 col-lg-6"
            }
          >
            <a
              className="w-100 btn btn-light font-weight-bold"
              style={{ background: "#3ACE5A", color: "white" }}
              onClick={formik.handleSubmit}
            >
              {t("Save")}
            </a>
          </div>
        </div>
      </Modal.Footer>

      {showLoading && (
        <div
          className="w-100 h-100 bg-dark-o-40 justify-content-center align-items-center d-flex"
          style={{ position: "absolute" }}
        >
          <LoadingOverlay />
        </div>
      )}
    </Modal>
    // </div>
  );
}
