import React from "react";
import PropTypes from "prop-types";

State.propTypes = {
  className: PropTypes.string,
  state: PropTypes.string,
};

State.defaultProps = {
  className: "",
  state: "",
};

//AFM: "AFM",  DFM: "DFM",  MIC: "MIC",  MP3: "MP3",

function State(props) {
  const { id, className, state } = props;

  var backgroundColor = "rgba(32, 161, 68, 0.1)";
  var color = "#20A144";
  var text = "";

  const stateUpperCase = state?.toUpperCase();

  if (stateUpperCase === "1") {
    backgroundColor = "rgba(229, 110, 25, 0.1)";
    color = "#E56E19";
    text = "Tạm dừng phát sóng";
  }
  if (stateUpperCase === "0") {
    backgroundColor = "rgba(54, 192, 103, 0.1)";
    color = "#36C067";
    text = "Đang phát";
  }
  if (stateUpperCase === "2") {
    backgroundColor = "#FFE2E5";
    color = "#F64E60";
    text = "Không phát";
  } else {
    backgroundColor = "#FFE2E5";
    color = "#F64E60";
    text = "Không phát";
  }
  // if (stateUpperCase === "AFM") {
  //   text = "AFM";
  // }
  // if (stateUpperCase === "DFM") {
  //   text = "DFM";
  // }
  // if (stateUpperCase === "MIC") {
  //   text = "MIC";
  // }
  // if (stateUpperCase === "MP3") {
  //   text = "MP3";
  // }

  return (
    <div
      id={id}
      className={`${className} d-inline-flex status-box`}
      style={{ backgroundColor: backgroundColor }}
    >
      <p
        id={id}
        className="Regular_13 text-capitalize"
        style={{ color: color }}
      >
        {text}
      </p>
    </div>
  );
}

export default State;
