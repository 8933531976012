import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import SelectAreaV2 from "components/SelectAreaV2/SelectAreaV2";
import { thunkCreateStation } from "pages/RadioStationManager/stationSlice";
import BaseTextField from "general/custom-fields/BaseTextField";
import "./style.scss";

ModalAddStation.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  rootAreaId: PropTypes.number,
};

ModalAddStation.defaultProps = {
  show: false,
  onClose: null,
};

function ModalAddStation(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedArea, setSelectedArea] = useState(null);
  const listArea = useSelector((state) => state.area.listArea);

  // MARK: --- Params ---
  const { show, onClose, rootAreaId } = props;

  // MARK: --- Formik ---
  const formik = useFormik({
    initialValues: {
      name: "",
      areaId: "",
      KinhDo: "",
      ViDo: "",
      MaDaiTruyenThanh: "",
    },
    validationSchema: yup.object({
      name: yup.string().required(t("Required")),
      areaId: yup.string().required(t("Required")),
    }),
    onSubmit: (values) => {
      console.log("Add station", values);
      const params = {
        ...values,
        Ten: values.name,
        MaKhuVuc: values.areaId,
        KinhDo: parseFloat(values.KinhDo),
        ViDo: parseFloat(values.ViDo),
      };
      delete params.name;
      delete params.areaId;
      dispatch(thunkCreateStation(params));
      handleClose();
      setSelectedArea(null);
      formik.handleReset();
    },
  });

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <Modal
      className="AddStation font-weight-bold"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title>
          <p className="Bold_13">{t("AddStation")}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <div
          className="mb-1"
          style={{
            fontSize: 12,
            fontWeight: 500,
            color: "#9EA5BD",
          }}
        >
          {t("Area")}
        </div>
        <SelectAreaV2
          onSelect={(area) => {
            formik.getFieldHelpers("areaId").setValue(area);
            formik
              .getFieldHelpers("MaDaiTruyenThanh")
              .setValue(area?.slice(0, 9) || "");
            setSelectedArea(listArea?.find((ar) => ar.MaKhuVuc == area));
          }}
          rootAreaId={rootAreaId}
          className="bg-white rounded mb-3"
          selectedArea={selectedArea}
        />

        <BaseTextField
          className="StationName Normal_12 mb-3"
          name="name"
          label={t("StationCode")}
          placeholder={t("StationCode")}
          fieldProps={formik.getFieldProps("MaDaiTruyenThanh")}
          fieldHelpers={formik.getFieldHelpers("MaDaiTruyenThanh")}
          fieldMeta={formik.getFieldMeta("MaDaiTruyenThanh")}
        />

        <BaseTextField
          className="StationName Normal_12 mb-3"
          name="name"
          label={t("StationName")}
          placeholder={t("StationName")}
          fieldProps={formik.getFieldProps("name")}
          fieldHelpers={formik.getFieldHelpers("name")}
          fieldMeta={formik.getFieldMeta("name")}
        />

        <BaseTextField
          className="StationName Normal_12 mb-3"
          name="name"
          label={t("KinhDo")}
          placeholder={t("KinhDo")}
          fieldProps={formik.getFieldProps("KinhDo")}
          fieldHelpers={formik.getFieldHelpers("KinhDo")}
          fieldMeta={formik.getFieldMeta("KinhDo")}
        />

        <BaseTextField
          className="StationName Normal_12 mb-3"
          name="name"
          label={t("ViDo")}
          placeholder={t("ViDo")}
          fieldProps={formik.getFieldProps("ViDo")}
          fieldHelpers={formik.getFieldHelpers("ViDo")}
          fieldMeta={formik.getFieldMeta("ViDo")}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="row w-100">
          <div className="col-6">
            <a
              className="w-100 btn btn-light  font-weight-bold"
              onClick={() => handleClose()}
            >
              {t("Cancel")}
            </a>
          </div>

          <div className="col-6">
            <a
              className="w-100 btn btn-primary font-weight-bold"
              onClick={() => {
                console.log("Add station click");
                formik.handleSubmit();
              }}
            >
              {t("Save")}
            </a>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAddStation;
