import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import { thunkCreateStation } from "pages/RadioStationManager/stationSlice";

import SelectArea from "components/SelectArea/SelectArea";
import BaseTextField from "general/custom-fields/BaseTextField";

import "./style.scss";
import { useHistory } from "react-router-dom";
import deviceApi from "api/deviceApi";
import ToastHelper from "general/helpers/ToastHelper";
import { current } from "@reduxjs/toolkit";
import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import SelectRadioStation from "components/SelectRadioStation/SelectRadioStation";
import bulletinBoardApi from "api/bulletinBoardApi";
import Utils, {
  findAreaById,
  findAreaByMaKhuVuc,
  findProvidersByMaNhaCungCap,
} from "general/utils/Utils";
import devicesApi from "api/devicesApi";

import styled from "styled-components";
import {
  BULLETIN_REPEAT_TYPE,
  SignalConnectionType,
} from "general/constants/Types";
import { SelectSort } from "pages/Category/components/SelectSort";
import _ from "lodash";
import { sha256 } from "js-sha256";

ModalAddDevice.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  rootAreaId: PropTypes.number,
  name: PropTypes.string,
};

ModalAddDevice.defaultProps = {
  show: false,
  onClose: null,
};

const AttributeRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  flex-direction: column;
  align-items: initial;
`;

const AttributeTitle = styled.div`
  width: 140px;
  max-width: 40%;
  margin-right: 12px;
  text-align: right;

  @media (min-width: 1200px) and (max-width: 1920px), (max-width: 576px) {
    width: initial;
    max-width: none;
    text-align: initial;
    margin-bottom: 5px;
  }
`;

const AttributeInput = styled.div`
  flex: 1;
`;
function ModalAddDevice(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.current);
  const [selectedArea, setSelectedArea] = useState(currentUser?.areaId ?? null);
  const [selectedRadioStation, setSelectedRadioStation] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const allAreas = useSelector((state) => state.area.allAreas);
  var origin = window.location.origin;
  // MARK: --- Params ---
  const { show, onClose, name, deviceType, setReload = () => {} } = props;
  const { providers } = useSelector((state) => state.provider);

  useEffect(() => {
    dispatch(thunkGetAreas({}));
  }, []);

  useEffect(() => {
    setSelectedArea(
      findAreaByMaKhuVuc(currentUser.MaKhuVuc ? currentUser.MaKhuVuc : -1)
    );
  }, [currentUser, loading, allAreas]);

  useEffect(() => {
    setSelectedRadioStation({});
  }, [selectedArea]);

  const saveChangeDevice = async (value) => {
    try {
      if (Utils.isObjectEmpty(selectedArea)) {
        ToastHelper.showError("Chưa chọn khu vực");
      } else if (Utils.isObjectEmpty(selectedRadioStation)) {
        ToastHelper.showError("Chưa chọn đài truyền thanh");
      } else {
        try {
          setLoading(true);
          let res = await devicesApi.createIpRadio(value);
          if (res?.data?.MoTa == "ThanhCong") {
            setSelectedArea(null);
            ToastHelper.showSuccess(`Đã thêm thiết bị thành công!`);

            formik.handleReset();
            setReload(Math.random().toString());
            handleClose();
          } else {
            ToastHelper.showSuccess(
              res?.data?.MoTa ?? `Thêm thiết bị thất bại !`
            );
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const saveChangeBulletinBoard = async (value) => {
    if (Utils.isObjectEmpty(selectedArea)) {
      ToastHelper.showError("Chưa chọn khu vực");
    } else if (Utils.isObjectEmpty(selectedRadioStation)) {
      ToastHelper.showError("Chưa chọn đài truyền thanh");
    } else {
      try {
        setLoading(true);
        const res = await devicesApi.createDigitalSignage(value);

        if (res.data.MoTa == "ThanhCong") {
          ToastHelper.showSuccess(`Đã thêm Bảng tin thành công!`);
          formik.handleReset();
          setReload(Math.random().toString());
          handleClose();
        } else {
          ToastHelper.showError(res.data.MoTa ?? "Thêm Bảng tin thất bại");
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        // setSubmitDisable(false);
        console.log(err);
      }
    }
  };

  // MARK: --- Formik ---
  const formik = useFormik({
    initialValues: {
      CumLoaID: "",
      MaKhuVuc: selectedArea?.MaKhuVuc,
      MaNhaCungCap: providers?.[0]?.MaNhaCungCap,
      KieuKetNoi: SignalConnectionType?.[1]?.value,
      Username: null,
      Password: null,
      TenThietBi: "",
    },
    validationSchema: (props) => {
      return yup.lazy((values) => {
        return yup.object({
          CumLoaID: yup.string().required(t("Required")),
          TenThietBi: yup.string().required(t("Required")),
          Username:
            values?.KieuKetNoi === SignalConnectionType?.[0]?.value
              ? yup.string().required(t("Required")).nullable()
              : yup.string().nullable(),
          Password:
            values?.KieuKetNoi === SignalConnectionType?.[0]?.value
              ? yup.string().required(t("Required")).nullable()
              : yup.string().nullable(),
        });
      });
    },
    // validationSchema: yup.object({
    //   // areaId: yup.number().required(t("Required")),
    // }),
    onSubmit: (values) => {
      // setSelectedArea(null);
      if (name == t("RadioDevice")) {
        saveChangeDevice({
          ...values,

          KieuKetNoi: values?.KieuKetNoi,
          MaNhaCungCap: values?.MaNhaCungCap ?? providers?.[0]?.MaNhaCungCap,
          TenNhaCungCap: findProvidersByMaNhaCungCap(
            values?.MaNhaCungCap ?? providers?.[0]?.MaNhaCungCap
          )?.Ten,
          Password: values?.Password && sha256(values?.Password),
          MaDaiTruyenThanh: selectedRadioStation?.MaDaiTruyenThanh,
          MaKhuVuc: selectedArea?.MaKhuVuc,
        });
      } else {
        saveChangeBulletinBoard({
          ...values,
          CumLoaID: null,
          BangTinDienTuID: values?.CumLoaID,
          KieuKetNoi: values?.KieuKetNoi,
          MaNhaCungCap: values?.MaNhaCungCap ?? providers?.[0]?.MaNhaCungCap,
          TenNhaCungCap: findProvidersByMaNhaCungCap(
            values?.MaNhaCungCap ?? providers?.[0]?.MaNhaCungCap
          )?.Ten,
          Password: values?.Password && sha256(values?.Password),
          MaKhuVuc: selectedArea?.MaKhuVuc,
        });
      }
      // formik.handleReset();
    },
  });

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <Modal
      className="AddDevice font-weight-bold"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        handleClose();
      }}
      onExited={() => {
        formik.handleReset();
      }}
    >
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title className="Bold_13">
          {t("Thêm")} {name.toLowerCase()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <AttributeRow className="mb-3">
          <div
            className="mt-3 mb-1"
            style={{
              fontSize: 12,
              fontWeight: 500,
              color: "#9EA5BD",
            }}
          >
            {t("ProviderName")} <span className="text-danger">(*)</span>
          </div>
          <AttributeInput>
            <SelectSort
              valueBtnStyle={{ width: "100%" }}
              selections={providers?.map((i) => {
                return {
                  value: i?.MaNhaCungCap,
                  display: i?.MaNhaCungCap,
                };
              })}
              currentValue={
                formik.values?.MaNhaCungCap ?? providers?.[0]?.MaNhaCungCap
              }
              onSelect={(item) => {
                formik.setValues({
                  ...formik.values,
                  MaNhaCungCap: item?.value,
                });
                // setRepeatType(item.value);
              }}
              // disable={disableAll}
            />
          </AttributeInput>
        </AttributeRow>
        <BaseTextField
          className="StationName Normal_12 mb-3"
          name="CumLoaID"
          label={
            <>
              {t("ID thiết bị ")}
              <span className="text-danger">(*)</span>
            </>
          }
          placeholder={t("ID thiết bị")}
          fieldProps={formik.getFieldProps("CumLoaID")}
          fieldHelpers={formik.getFieldHelpers("CumLoaID")}
          fieldMeta={formik.getFieldMeta("CumLoaID")}
        />
        <BaseTextField
          className="StationName Normal_12"
          name="TenThietBi"
          label={
            <>
              {t("DeviceName")}
              <span className="text-danger"> (*)</span>
            </>
          }
          placeholder={t("DeviceName")}
          fieldProps={formik.getFieldProps("TenThietBi")}
          fieldHelpers={formik.getFieldHelpers("TenThietBi")}
          fieldMeta={formik.getFieldMeta("TenThietBi")}
        />

        <div
          className="mt-3 mb-1"
          style={{
            fontSize: 12,
            fontWeight: 500,
            color: "#9EA5BD",
          }}
        >
          {t("Area")} <span className="text-danger">(*)</span>
        </div>
        <SelectArea
          className="bg-white rounded"
          width={0}
          onSelect={(area) => {
            setSelectedArea(area);
          }}
          rootAreaId={currentUser.MaKhuVuc}
          selectedArea={selectedArea}
        />
        {name == t("BulletinBoard") ? null : (
          <>
            <div
              className="mt-3 mb-1"
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: "#9EA5BD",
              }}
            >
              Đài truyền thanh
            </div>
            <SelectRadioStation
              className="bg-white rounded"
              onSelect={(radioStation) => {
                setSelectedRadioStation(radioStation);
              }}
              MaKhuVuc={
                selectedArea ? selectedArea?.MaKhuVuc : currentUser?.MaKhuVuc
              }
              selectedRadioStation={
                selectedRadioStation ??
                findAreaByMaKhuVuc(currentUser?.MaKhuVuc)
              }
              // deviceStationId={device.radioStationId}
              noBorder={false}
            />{" "}
          </>
        )}
        <AttributeRow className="mb-3">
          <div
            className="mt-3 mb-1"
            style={{
              fontSize: 12,
              fontWeight: 500,
              color: "#9EA5BD",
            }}
          >
            {t("SignalConnectionType")}
          </div>
          <AttributeInput>
            <SelectSort
              valueBtnStyle={{ width: "100%" }}
              selections={SignalConnectionType}
              currentValue={
                _.find(SignalConnectionType, {
                  value: formik.values?.KieuKetNoi,
                })?.display ?? SignalConnectionType?.[1]?.display
              }
              onSelect={(item) => {
                formik.setValues({
                  ...formik.values,
                  KieuKetNoi: item?.value,
                });
                // setRepeatType(item.value);
              }}
              // disable={disableAll}
            />
          </AttributeInput>
        </AttributeRow>
        {formik.values?.KieuKetNoi === SignalConnectionType?.[0]?.value && (
          <>
            <BaseTextField
              className="StationName Normal_12 mb-3"
              name="Username"
              label={
                <>
                  {t("Username")} <span className="text-danger">(*)</span>
                </>
              }
              placeholder={t("Username")}
              fieldProps={formik.getFieldProps("Username")}
              fieldHelpers={formik.getFieldHelpers("Username")}
              fieldMeta={formik.getFieldMeta("Username")}
            />
            <BaseTextField
              className="StationName Normal_12 "
              name="Password"
              label={
                <>
                  {t("password")} <span className="text-danger">(*)</span>
                </>
              }
              placeholder={t("password")}
              fieldProps={formik.getFieldProps("Password")}
              fieldHelpers={formik.getFieldHelpers("Password")}
              fieldMeta={formik.getFieldMeta("Password")}
              type={"password"}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="row w-100">
          <div className="col-6">
            <a
              className="w-100 btn btn-light  font-weight-bold"
              onClick={() => handleClose()}
            >
              {t("Cancel")}
            </a>
          </div>

          <div className="col-6">
            <a
              className="w-100 btn btn-primary font-weight-bold"
              onClick={() => {
                loading == false ? formik.handleSubmit() : null;
              }}
            >
              {loading === false ? null : (
                <span className="spinner-border spinner-border-sm mr-2 mt-1"></span>
              )}
              {t("Save")}
            </a>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAddDevice;
