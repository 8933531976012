import axiosClient from "./axiosClient";

const digitalsignageApi = {
  getDevicesInArea: (params) => {
    const url = `/devices/digitalsignage`;
    return axiosClient.get(url, { params });
  },
};

export default digitalsignageApi;
