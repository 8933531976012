import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Account, Area } from "../../../../general/types/index.d";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import BaseTextField from "../../../../general/custom-fields/BaseTextField";
import SelectArea from "../../../../components/SelectArea/SelectArea";
import { useDispatch, useSelector } from "react-redux";
import KeenChangeAvatar from "../../../../components/Keen/KeenChangeAvatar";
import KeenSelectOption from "../../../../components/Keen/KeenSelectOption";
import ReactSwitch from "react-switch";
import userApi from "../../../../api/userApi";
import ToastHelper from "../../../../general/helpers/ToastHelper";
import AppPage from "components/AppPage";
import { AppImages } from "general/constants/AppResource";
import AccountCard from "components/AccountCard";
import NavBar from "components/NavBar";
import CardDetailHaveButton from "components/CardDetailHaveButton";
import CardDetail from "components/CardDetail";
import Switch from "react-switch";
import "./styles.scss";
import { setAllAreas } from "pages/AreaManager/areaSlice";
import areaApi from "api/areaApi";
import ModalChangePassword from "pages/AccountManager/components/ModalChangePassword";
import ModalUpdateAccount from "pages/AccountManager/components/ModalUpdateAccount";
import ModalDeleteAccount from "pages/AccountManager/components/ModalDeleteAccount";
import CheckBox from "pages/Login/components/inputs/checkBox";
import Select from "react-select";
import authApi from "api/authApi";
import { thunkGetCurrentUserInfo } from "pages/Login/authSlice";
import ModalOTP from "pages/AccountManager/components/ModalOTP";
/**
 * @description depend on `formik` and `yup` lib
 *
 * @param {Object} props
 * @param {string=} props.className wrapper component class name
 * @param {boolean=} props.show is show or not
 * @param {(success?:boolean)=>void=} props.onDismiss onDismiss callback
 * @param {()=>void=} props.handleDelete when press delete button
 * @param {Account} props.account account
 */

let hiddenEmail;

export default function AccountOverview(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [visibilityVBEE, setVisibilityVBEE] = useState("invisible");
  const [modalChangePassword, setModalChangePassword] = useState(false);
  const [modalUpdateAccount, setModalUpdateAccount] = useState(false);
  const [modalDeleteAccount, setModalDeleteAccount] = useState(false);
  const [modalOTP, setModalOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOTP, setLoadingOTP] = useState(false);

  const defaultValueSelect = (options, value) => {
    return options ? options.find((option) => option.value === value) : "";
  };

  /**
   * @type {Area[]}
   */
  const allAreas = useSelector((state) => state.area.allAreas);
  /**
   * @type {Account}
   */
  const currentUser = useSelector((state) => state.auth.current);

  let {} = props;

  /**
   *
   * @param {boolean=} success
   */
  const handleCloseModalUpdateAccount = () => {
    setModalUpdateAccount(false);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t("Required")),
    fullname: Yup.string().required(t("Required")),
    phone: Yup.string()
      .matches(phoneRegExp, t("InvalidPhoneNumber"))
      .required(t("Required")),
    email: Yup.string().email().nullable().required(t("Required")),
    vbeeWordLimit: Yup.number().min(0, t("Invalid")).nullable().notRequired(),
    vbeeWordUsed: Yup.number().min(0, t("Invalid")).nullable().notRequired(),
  });
  function getArea(areaId) {
    for (let i = 0; i < allAreas.length; i++)
      if (allAreas[i].id === areaId) {
        return allAreas[i];
      }
    return null;
  }
  let area = getArea(currentUser?.MaKhuVuc);
  const formik = useFormik({
    // initialValues: {
    //   // ...account,
    //   avatarLink: currentUser?.AnhDaiDien ?? AppImages.imgDefaultAvatar.default,
    //   username: currentUser?.TaiKhoan,
    //   fullname: currentUser?.Ten,
    //   phone: currentUser?.SDT,
    //   email: currentUser?.Email,
    //   position: currentUser?.ViTri,
    //   userId: currentUser?.MaNguoiDung,
    //   area: area ? area.name : "",
    //   areaId: currentUser?.MaKhuVuc ?? currentUser?.area?.id,
    //   vbeeWordLimit: currentUser?.vbee?.monthLimitCharacters,
    //   vbeeWordUsed: currentUser?.vbee?.monthUsedCharacters,
    //   broadcast: !!currentUser?.broadcast,
    //   deviceConfiguration: "",
    //   editAccount: !!currentUser?.editAccount,
    //   acceptProgram: !!currentUser?.PheDuyetBanTin,
    //   editProgram: !!currentUser?.QuanLyBanTin,
    //   editSpeaker: !!currentUser?.QuanLyThietBi,
    //   avatarFile: null,
    //   twoEnableFactor: !!currentUser?.TwoFactor,
    //   token: "",
    //   useTTS: !!currentUser?.useTTS,
    //   managerArea: !!currentUser?.QuanLyKhuVuc,
    //   managerRadioStation: !!currentUser?.managerRadioStation,
    //   managerAccountProvider: !!currentUser?.managerAccountProvider,
    //   managerProvider: !!currentUser?.QuanLyNhaCungCap,
    // },

    initialValues: {
      AnhDaiDien: currentUser?.AnhDaiDien ?? AppImages.imgDefaultAvatar.default,
      TaiKhoan: currentUser?.TaiKhoan,
      Ten: currentUser?.Ten,
      Cap: currentUser?.Cap,
      SDT: currentUser?.SDT,
      Email: currentUser?.Email,
      ViTri: currentUser?.ViTri,
      MaNguoiDung: currentUser?.MaNguoiDung,
      TrangThai: currentUser?.TrangThai,
      MaKhuVuc: currentUser?.MaKhuVuc ?? currentUser?.area?.id,
      TwoFactor: !!currentUser?.TwoFactor,

      //start
      TTS: !!currentUser?.TTS,
      QuanLyTaiKhoan: !!currentUser?.QuanLyTaiKhoan,
      //end
      // QuanLyBanTin: !!currentUser?.QuanLyBanTin,
      // QuanLyLichPhat: !!currentUser?.QuanLyLichPhat,
      // QuanLyThietBi: !!currentUser?.QuanLyThietBi,
      // QuanLyTaiKhoan: !!currentUser?.QuanLyTaiKhoan,
      // PheDuyetBanTin: !!currentUser?.PheDuyetBanTin,
      // PheDuyetLichPhat: !!currentUser?.PheDuyetLichPhat,
      // TTS: !!currentUser?.TTS,
      // QuanLyKhuVuc: !!currentUser?.QuanLyKhuVuc,
      // QuanLyNhaCungCap: !!currentUser?.QuanLyNhaCungCap,
      managerRadioStation: !!currentUser?.managerRadioStation,
      managerAccountProvider: !!currentUser?.managerAccountProvider,
      ttsCount: currentUser?.ttsCount,
      ttsLimit: currentUser?.ttsLimit,

      // old api
      vbeeWordLimit: currentUser?.vbee?.monthLimitCharacters,
      vbeeWordUsed: currentUser?.vbee?.monthUsedCharacters,
      broadcast: !!currentUser?.broadcast,
      deviceConfiguration: "",
      area: area ? area.name : "",
      token: "",
      avatarFile: null,
    },

    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      console.log(values);
      try {
        values["vbee"] = JSON.stringify({
          monthLimitCharacters: values.vbeeWordLimit,
          monthUsedCharacters: values.vbeeWordUsed,
          username: values.username,
          applicationId: "com.ipfm.edited",
          dictionaryId: "com",
        });
        values["userId"] = values.userId;
        let res = (await authApi.updateCurrentUserInfo(values)).data;
        console.log(res);
        if (res) {
        }
        if (res && res.result == "success") {
          ToastHelper.showSuccess("Cập nhật tài khoản thành công");
          dispatch(thunkGetCurrentUserInfo({}));
          handleCloseModalUpdateAccount();
        } else {
          ToastHelper.showError("Cập nhật tài khoản thất bại");
        }
        setLoading(false);
      } catch (error) {
        ToastHelper.showError("Cập nhật tài khoản thất bại");
        setLoading(false);
      }
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  const onSubmitModalOTP = async (values) => {
    try {
      let res = await authApi.verifyOTP(values);
      if (res) {
        let data = res.data;
        setLoading(false);
        if (data.MoTa === "ThanhCong") {
          dispatch(thunkGetCurrentUserInfo({}));
          ToastHelper.showSuccess("Thay đổi thành công");
          setModalOTP(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const receiveOTP = async () => {
    setLoadingOTP(true);
    try {
      let res = await authApi.enableTwoFactor();
      if (res) {
        setLoadingOTP(false);
        if (res?.data?.TrangThaiGui === 0) {
          setModalOTP(true);
          ToastHelper.showSuccess(
            "Mã OTP đã được gửi tới email " + hiddenEmail
          );
        }
      }
    } catch (err) {
      setLoadingOTP(false);
    }
  };

  const VBEE = () => {
    return (
      <div>
        <div className="row bg-light border border-top-0 mx-0 py-2">
          <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
            {t("Application ID")}
          </div>
          <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
            <BaseTextField
              className=""
              name="applicationId"
              fieldProps={formik.getFieldProps("applicationId")}
              fieldMeta={formik.getFieldMeta("applicationId")}
              fieldHelper={formik.getFieldHelpers("applicationId")}
            />
          </div>
        </div>

        <div className="row bg-light border border-top-0 mx-0 py-2">
          <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
            {t("VBEE Token")}
          </div>
          <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
            <BaseTextField
              className=""
              // label="Username"
              name="VBEEToken"
              fieldProps={formik.getFieldProps("VBEEToken")}
              fieldMeta={formik.getFieldMeta("VBEEToken")}
              fieldHelper={formik.getFieldHelpers("VBEEToken")}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleChange = (nextChecked) => {
    checked == true ? setChecked(false) : setChecked(true);
    checked == true
      ? setVisibilityVBEE("visible")
      : setVisibilityVBEE("invisible");
  };

  async function getAreas() {
    const res = await areaApi.getAreas();
    const { result, areas } = res.data;
    if (result == "success" && areas) {
      dispatch(setAllAreas(areas));
    }
  }

  useEffect(() => {
    getAreas();
  }, []);

  useEffect(() => {
    if (currentUser.Email) {
      hiddenEmail = currentUser.Email.slice(0, 4);
      for (let i = 0; i < currentUser.Email.length - 4; i++) {
        hiddenEmail += "*";
      }
    }
  }, [currentUser.Email]);

  return (
    <AppPage headerTitle={t("AccountOverview")}>
      <NavBar navBarTitle={t("AccountOverview")}></NavBar>
      <div className="d-flex flex-column flex-column-fluid my-2">
        <AccountCard
          title={t("AccountOverview")}
          clickSave={() => formik.handleSubmit()}
          // clickDelete={() => setModalDeleteAccount(true)}
          clickDelete={() => setModalOTP(true)}
          blueButtonText={"Lưu Lại Thay Đổi Gần Nhất"}
          whiteButtonText={t("Delete")}
          loading={loading}
        >
          <form autoComplete="off" className="container-fluid w-100">
            <div className="row">
              <div className="col-md-6 ">
                <CardDetail
                  title={t("Account")}
                  className="justify-content-center"
                  bottomComponent={
                    <div
                      className="bg-white p-2 text-center cursor-pointer hover-opacity-80"
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById("addAvatar").click();
                      }}
                    >
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        Thay Ảnh Đại Diện
                      </p>
                    </div>
                  }
                  // buttonTitle={t("ChangeProfilePicture")}
                >
                  <KeenChangeAvatar
                    className="justify-content-center align-items-center d-flex text-hover-danger"
                    imageClassName="w-200px h-200px"
                    disabledRevokeUrl
                    name="AnhDaiDien"
                    fieldProps={formik.getFieldProps("AnhDaiDien")}
                    fieldMeta={formik.getFieldMeta("AnhDaiDien")}
                    onFileSelected={([file]) => {
                      formik.getFieldHelpers("avatarFile").setValue(file);
                    }}
                    idInput={"addAvatar"}
                  />
                </CardDetail>

                <CardDetail title={t("Authority")}>
                  <div className="w-100 ml-5">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        ...formik.values,
                      }}
                    >
                      <Form>
                        <div className="d-flex flex-column justify-content-start">
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("PlayStopTheProgram")}
                            name="PhatDungChuongTrinh"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("DeviceConfiguration")}
                            name="CauHinhThietBi"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("useTTS")}
                            name="TTS"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("AddEditDeleteBroadcastSchedule")}
                            name="ThemSuaXoaLichPhat"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("AccountManagement")}
                            name="QuanLyTaiKhoan"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("ApproveSchedule")}
                            name="PheDuyetLichPhat"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          {/* start */}
                          {/* <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("BulletinManager")}
                            name="QuanLyBanTin"
                            onChange={formik.handleChange}
                            disabled={true}
                          />

                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("DeviceManager")}
                            name="QuanLyThietBi"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("AccountManagement")}
                            name="QuanLyTaiKhoan"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("BulletinApprove")}
                            name="PheDuyetBanTin"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("ApproveSchedule")}
                            name="PheDuyetLichPhat"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("useTTS")}
                            name="TTS"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("AreaManager")}
                            name="QuanLyKhuVuc"
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <CheckBox
                            className="mb-4"
                            control="oneCheckbox"
                            label={t("managerProvider")}
                            name="QuanLyNhaCungCap"
                            onChange={formik.handleChange}
                            disabled={true}
                          /> */}
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </CardDetail>
              </div>
              {/*  */}
              <div className="col-md-6 ">
                <CardDetailHaveButton
                  title={t("GeneralInformation")}
                  buttonTitle={
                    currentUser?.TwoFactor
                      ? t("turnOffTwoFactorAuthentication")
                      : t("twoFactorAuthentication")
                  }
                  onClick={receiveOTP}
                  twoFactor={currentUser?.TwoFactor}
                  isLoading={loadingOTP}
                >
                  <div className="w-100 mx-5">
                    <div className="row">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("userName")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          disabled={true}
                          name="TaiKhoan"
                          showBorder={false}
                          fieldProps={formik.getFieldProps("TaiKhoan")}
                          fieldMeta={formik.getFieldMeta("TaiKhoan")}
                          fieldHelper={formik.getFieldHelpers("TaiKhoan")}
                        />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("FullName")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="Ten"
                          fieldProps={{
                            ...formik.getFieldProps("Ten"),
                          }}
                          fieldMeta={formik.getFieldMeta("Ten")}
                        />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("PhoneNumber")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="SDT"
                          fieldProps={formik.getFieldProps("SDT")}
                          fieldMeta={formik.getFieldMeta("SDT")}
                        />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("Email")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="Email"
                          fieldProps={{
                            ...formik.getFieldProps("Email"),
                          }}
                          fieldMeta={formik.getFieldMeta("Email")}
                        />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("Position")}
                      </div>
                      {/* <Select
                        value={defaultValueSelect(
                          optionsPosition,
                          formik.values.position
                        )}
                        onChange={(value) =>
                          formik.setFieldValue("position", value.value)
                        }
                        components={{ IndicatorSeparator: () => null }}
                        styles={dropdownSelectStyle}
                        options={optionsPosition}
                        name="position"
                        className="p-0 col-8"
                        placeholder={t("ChoosePosition")}
                      /> */}
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="ViTri"
                          disabled={true}
                          showBorder={false}
                          fieldProps={{
                            ...formik.getFieldProps("ViTri"),
                          }}
                          fieldMeta={formik.getFieldMeta("ViTri")}
                        />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12 col-sm-4 col-md-12 col-xl-4 labelInput">
                        {t("Area")}
                      </div>
                      <div className="col-12 col-sm-8 col-md-12 col-xl-8 inputField">
                        <BaseTextField
                          className=""
                          name="area"
                          disabled={true}
                          showBorder={false}
                          fieldProps={{
                            ...formik.getFieldProps("area"),
                          }}
                          fieldMeta={formik.getFieldMeta("area")}
                        />
                      </div>
                    </div>
                  </div>
                </CardDetailHaveButton>
                <CardDetail title={t("SpeechSynthesis")}>
                  <div className="w-100 mx-5">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
                        {t("LimitedNumberOfCharactersForTheMonth")}
                      </div>
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
                        <BaseTextField
                          className=""
                          name="ttsLimit"
                          fieldProps={{
                            ...formik.getFieldProps("ttsLimit"),
                          }}
                          fieldMeta={formik.getFieldMeta("ttsLimit")}
                        />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 labelInput">
                        {t("NumberOfCharactersUsedInTheMonth")}
                      </div>
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 inputField">
                        <BaseTextField
                          className=""
                          name="ttsCount"
                          fieldProps={{
                            ...formik.getFieldProps("ttsCount"),
                          }}
                          fieldMeta={formik.getFieldMeta("ttsCount")}
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-row justify-content-center align-items-center border py-3 mt-5">
                      <div className="labelInput mr-5 mb-0">
                        {t("VBEEAccountConfiguration")}
                      </div>
                      <Switch
                        onChange={handleChange}
                        checked={checked}
                        className="switch"
                        onColor="#3465E5"
                        uncheckedIcon={false}
                        checkedIcon={false}
                      />
                    </div>
                    {checked == true ? VBEE() : null}
                  </div>
                </CardDetail>
                <CardDetail title={t("password")}>
                  <div className="w-100 mx-5">
                    <div className="row">
                      <div className="col-12 col-md-4   labelInput">
                        {t("password")}
                      </div>
                      <div className="col-12 col-md-8  inputField rounded ">
                        <div className="bg-light p-3  d-flex align-items-center ">
                          ********
                        </div>
                      </div>
                    </div>

                    <div
                      className="d-flex flex-row justify-content-center align-items-center border py-3 mt-5 cursor-pointer rounded hover-opacity-80"
                      onClick={() => {
                        setModalChangePassword(true);
                      }}
                    >
                      <div className="labelInput mr-5 mb-0 ">
                        {t("ChangePassword")}
                      </div>
                    </div>
                  </div>
                </CardDetail>
              </div>
            </div>
          </form>
        </AccountCard>
      </div>
      <ModalChangePassword
        show={modalChangePassword}
        onClose={() => setModalChangePassword(false)}
      />
      <ModalUpdateAccount
        show={modalUpdateAccount}
        onClose={() => setModalUpdateAccount(false)}
        submitOnclick={() => formik.handleSubmit()}
        loading={loading}
      />
      <ModalDeleteAccount
        show={modalDeleteAccount}
        onClose={() => setModalDeleteAccount(false)}
      />
      <ModalOTP
        show={modalOTP}
        onClose={() => setModalOTP(false)}
        onSubmitModalOTP={onSubmitModalOTP}
      />
    </AppPage>
  );
}
