import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import providerApi from "api/providerApi";
export const thunkGetProvider = createAsyncThunk(
  "providers/getAll",
  async (params, thunkAPI) => {
    const res = await providerApi.getProviders();
    return res;
  }
);
const providerSlice = createSlice({
  name: "provider",
  initialState: {
    isPending: false,
    providers: [],
    accounts: [],
    selectedProvider: null,
    selectedAccount: null,
  },
  reducers: {
    setProviders: (state, action) => {
      state.providers = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    addProvider: (state, action) => {
      state.providers = [...state.providers, action.payload];
    },
    addAccount: (state, action) => {
      state.accounts = [...state.accounts, action.payload];
    },
    updateProvider: (state, action) => {
      for (let i = 0; i < state.providers.length; i++)
        if (state.providers[i].id === action.payload.id) {
          state.providers[i].Ten = action.payload.Ten;
          state.providers[i].Email = action.payload.Email;
          state.providers[i].LienHe = action.payload.LienHe;
          state.providers[i].DiaChi = action.payload.DiaChi;
          state.providers[i].MaNhaCungCap = action.payload.MaNhaCungCap;
          break;
        }
    },
    updateAccount: (state, action) => {
      for (let i = 0; i < state.accounts.length; i++)
        if (state.accounts[i].id === action.payload.id) {
          state.accounts[i].serverUrl = action.payload.serverUrl;
          state.accounts[i].account = action.payload.account;
          state.accounts[i].password = action.payload.password;
          break;
        }
    },
    deleteProvider: (state, action) => {
      if (!Array.isArray(action.payload))
        state.providers = state.providers.filter(
          (item) => item.id !== action.payload
        );
      else
        for (let provider of action.payload)
          state.providers = state.providers.filter(
            (item) => item.id !== provider
          );
    },
    deleteAccount: (state, action) => {
      if (!Array.isArray(action.payload))
        state.accounts = state.accounts.filter(
          (item) => item.id !== action.payload
        );
      else
        for (let account of action.payload)
          state.accounts = state.accounts.filter((item) => item.id !== account);
    },
    setSelectedProvider: (state, action) => {
      state.selectedProvider = action.payload;
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
  },
  extraReducers: {
    [thunkGetProvider.pending]: (state, action) => {
      state.isPending = true;
    },
    [thunkGetProvider.rejected]: (state, action) => {
      state.isPending = false;
    },
    [thunkGetProvider.fulfilled]: (state, action) => {
      state.isPending = false;
      const { MoTa, NoiDung } = action.payload.data;
      if (MoTa == "ThanhCong") {
        state.providers = NoiDung?.NhaCungCap;
      }
    },
  },
});

const { actions, reducer } = providerSlice;

export const {
  setSelectedProvider,
  setSelectedAccount,
  setProviders,
  setAccounts,
  addProvider,
  addAccount,
  updateProvider,
  updateAccount,
  deleteProvider,
  deleteAccount,
} = actions;
export default reducer;
