import icLogo from "assets/icons/ic_logo.svg";
import icLogoNew from "assets/icons/logo_new.svg";
import icLogoHeader from "assets/icons/logo.svg";
import icMenuHandle from "assets/icons/ic_menu_handle.svg";
import icFlagEn from "assets/images/flags/ic_flag_en.svg";
import icFlagUs from "assets/images/flags/ic_flag_us.svg";
import icFlagVi from "assets/images/flags/ic_flag_vi.svg";
import icLogoLogin from "../../assets/icons/ic_logo_login.png";
import icLogoFooter from "../../assets/icons/ic_mdc_logo_footer.png";
import icNotification from "../../assets/icons/ic_notification.svg";
import icAccount from "../../assets/icons/ic_account.svg";
import icPlay from "../../assets/icons/ic_play.svg";
import icSperator from "../../assets/icons/ic_sperator.svg";
import icNotificationNavBar from "../../assets/icons/ic_notification_navbar.svg";
import ic3G from "../../assets/icons/ic_3G.svg";
import ic4G from "../../assets/icons/ic_4G.svg";
import icOffline from "../../assets/icons/ic_offline.svg";
import icBell from "../../assets/icons/ic_bell.svg";
import icTrash from "../../assets/icons/ic_trash.svg";
import icRadiotor from "../../assets/icons/ic_radiotor.png";
import icRadio from "../../assets/icons/ic_radio.png";
import icCheck from "../../assets/icons/ic_check.svg";
import icTrashCan from "../../assets/icons/ic_trash_can.svg";
import icActiveSpeaker from "../../assets/icons/ic_active_speaker.svg";
import icDisactiveSpeaker from "../../assets/icons/ic_disactive_speaker.svg";
import icNoNotification from "../../assets/icons/ic_no_notification.svg";
import icCalendarClock from "../../assets/icons/ic_calendar_clock.svg";
import icNotificationDotRed from "../../assets/icons/ic_notification_dot_red.svg";
import icDotRed from "../../assets/icons/ic_dot_red.svg";
import icTowerCell from "../../assets/icons/ic_tower_cell.svg";
import icActiveBulletin from "../../assets/icons/ic_active_bulletin.svg";
import icDisactiveBulletin from "../../assets/icons/ic_disactive_bulletin.svg";
import icBulletinBoard from "../../assets/icons/ic_bulletin_board.svg";
import icBulletinBoardRed from "../../assets/icons/ic_bulletin_board_red.svg";

// Icons
export const AppIcons = {
  icLogo,
  icLogoLogin,
  icLogoHeader,
  icMenuHandle,
  icLogoFooter,
  icFlagEn,
  icFlagUs,
  icFlagVi,
  icNotification,
  icAccount,
  icPlay,
  icSperator,
  icNotificationNavBar,
  ic3G,
  ic4G,
  icOffline,
  icBell,
  icTrash,
  icCheck,
  icTrashCan,
  icRadiotor,
  icRadio,
  icActiveSpeaker,
  icDisactiveSpeaker,
  icNoNotification,
  icCalendarClock,
  icNotificationDotRed,
  icDotRed,
  icTowerCell,
  icActiveBulletin,
  icDisactiveBulletin,
  icBulletinBoard,
  icBulletinBoardRed,
  icLogoNew,
};

// Images
export const AppImages = {
  img404: require("assets/images/404.jpg"),
  dashBoardBackground: require("assets/images/img_background_1.tiny.png"),
  radioStationBackground: require("assets/images/img_background_2.tiny.png"),
  providerBackground: require("assets/images/img_background_3.tiny.png"),
  areaBackground: require("assets/images/img_background_4.tiny.png"),
  imgBlankAvatar: require("assets/images/img_blank_avatar.tiny.png"),
  imgBlankImage: require("assets/images/img_blank_image.tiny.png"),
  imgBackground1: require("assets/images/img_background_1.tiny.png"),
  imgBackground2: require("assets/images/img_background_2.tiny.png"),
  imgBackground3: require("assets/images/img_background_3.tiny.png"),
  imgBackground4: require("assets/images/img_background_4.tiny.png"),
  imgBackground5: require("assets/images/img_background_5.tiny.png"),
  imgBackground6: require("assets/images/img_background_6.tiny.png"),
  imgBackground7: require("assets/images/img_background_7.tiny.png"),
  imgBackground8: require("assets/images/img_background_8.tiny.png"),
  imgBackground9: require("assets/images/img_background_9.tiny.png"),
  imgBackground10: require("assets/images/img_background_10.tiny.png"),
  imgBackground11: require("assets/images/img_background_11.tiny.png"),
  imgBackground13: require("assets/images/img_background_13.tiny.png"),
  imgBackground14: require("assets/images/img_background_14.tiny.png"),
  imgBackground15: require("assets/images/img_background_15.tiny.png"),
  imgDefaultAvatar: require("assets/images/img_default_avatar.svg"),
  imgGroupIcon: require("assets/images/group_icon.png"),
  // imgBackgroundEBulletin: require("assets/images/img_background_eBulletin.tiny.png"),
  imgNotiModalBackground: require("assets/images/img_notification_modal_background.png"),
  headerBackgoundImages: [
    require("assets/images/img_background_1.tiny.png"),
    require("assets/images/img_background_2.tiny.png"),
    require("assets/images/img_background_3.tiny.png"),
    require("assets/images/img_background_4.tiny.png"),
    require("assets/images/img_background_5.tiny.png"),
    require("assets/images/img_background_6.tiny.png"),
    require("assets/images/img_background_7.tiny.png"),
    require("assets/images/img_background_8.tiny.png"),
    require("assets/images/img_background_9.tiny.png"),
    require("assets/images/img_background_10.tiny.png"),
    require("assets/images/img_background_11.tiny.png"),
    require("assets/images/img_background_12.tiny.png"),
    require("assets/images/img_background_13.tiny.png"),
    require("assets/images/img_background_14.tiny.png"),
    require("assets/images/img_background_15.tiny.png"),
    require("assets/images/img_background_16.tiny.png"),
  ],
  headerBackgoundImagesSlider: [
    require("assets/images/bg_header_hai_phong/bg_1.png"),
    require("assets/images/bg_header_hai_phong/bg_2.png"),
    require("assets/images/bg_header_hai_phong/bg_3.png"),
    require("assets/images/bg_header_hai_phong/bg_4.png"),
    require("assets/images/bg_header_hai_phong/bg_5.png"),
    require("assets/images/bg_header_hai_phong/bg_6.png"),
    require("assets/images/bg_header_hai_phong/bg_7.png"),
    require("assets/images/bg_header_hai_phong/bg_8.png"),
    require("assets/images/bg_header_hai_phong/bg_9.png"),
    require("assets/images/bg_header_hai_phong/bg_10.png"),
    require("assets/images/bg_header_hai_phong/bg_11.png"),
    require("assets/images/bg_header_hai_phong/bg_12.png"),
    require("assets/images/bg_header_hai_phong/bg_13.png"),
    require("assets/images/bg_header_hai_phong/bg_14.png"),
    require("assets/images/bg_header_hai_phong/bg_15.png"),
  ],
};
