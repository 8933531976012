import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authApi from "api/authApi";
import axios from "axios";
import PreferenceKeys from "general/constants/PreferenceKeys";
import Global from "general/Global";

export const thunkSignIn = createAsyncThunk(
  "oauth/login",
  async (params, thunkAPI) => {
    const res = await authApi.signIn(params);
    return res;
  }
);

export const thunkVerifyToken = createAsyncThunk(
  "oauth/verifyToken",
  async (params, thunkAPI) => {
    const res = await authApi.verifyToken(params);
    // debugger;
    return res;
  }
);

export const thunkGetCurrentUserInfo = createAsyncThunk(
  "users/me",
  async (params, thunkAPI) => {
    const res = await authApi.getCurrentUserInfo(params);
    return res;
  }
);

export const thunkUpdateCurrentUserInfo = createAsyncThunk(
  "users/edit",
  async (params, thunkAPI) => {
    const res = await authApi.updateCurrentUserInfo(params);
    return res;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isSigningIn: false,
    current: {},
  },
  reducers: {
    signOut: (state, action) => {
      state.error = "";
      state.current = {};
    },
  },
  extraReducers: {
    //sign In
    [thunkSignIn.pending]: (state, action) => {
      state.isSigningIn = true;
    },
    [thunkSignIn.rejected]: (state, action) => {
      state.isSigningIn = false;
    },
    [thunkSignIn.fulfilled]: (state, action) => {
      state.isSigningIn = false;

      const account = action?.payload?.data?.NoiDung;
      if (account) {
        state.current = account;
        const { Token, RefreshToken, TwoFactor } = account;
        // Dang nhap khong co 2fa
        if (Token && RefreshToken && TwoFactor === 0) {
          localStorage.setItem(PreferenceKeys.accessToken, Token);
          localStorage.setItem(PreferenceKeys.refreshToken, RefreshToken);
          Global.accessToken = Token;
          Global.refreshToken = RefreshToken;
          // debugger
        }
        // Dang nhap co 2fa
        if (Token && RefreshToken && TwoFactor === 1) {
          localStorage.setItem(PreferenceKeys.accessToken, Token);
          Global.accessToken = Token;
          Global.initialRefreshToken = RefreshToken;
          // debugger
        }
      }
    },

    [thunkVerifyToken.fulfilled]: (state, action) => {
      // debugger;
      const Token = action?.payload?.data?.NoiDung?.Token;
      if (Token) {
        localStorage.setItem(PreferenceKeys.accessToken, Token);
        Global.accessToken = Token;
      }
    },

    [thunkGetCurrentUserInfo.fulfilled]: (state, action) => {
      const account = action?.payload?.data?.NoiDung;
      if (account) {
        state.current = account;
      }
    },
  },
});

const { reducer, actions } = authSlice;

export const { signOut } = actions;
export default reducer;
