import { useState } from "react";
import Utils from "general/utils/Utils";

function useSort(dictArray, updateCallback, setOrder) {
  const [sortHistory, setSortHistory] = useState({ propName: "", isSorted: 0 });
  const [inputLength, setInputLength] = useState(0);

  if (dictArray?.length != inputLength) {
    setSortHistory({ propName: "", isSorted: 0 });
    setInputLength(dictArray?.length);
  }

  function sortOnClick(propName) {
    if (setOrder) {
      if (propName === sortHistory.propName && sortHistory.isSorted != 0) {
        setOrder({
          field: propName,
          mode: sortHistory.isSorted == -1 ? "DESC" : "ASC",
        });
        setSortHistory({ propName: propName, isSorted: -sortHistory.isSorted });
      } else {
        setOrder({ field: propName, mode: "ASC" });
        setSortHistory({ propName: propName, isSorted: -1 });
      }
    } else {
      var mutableArray = [...dictArray];
      const type = typeof mutableArray.at(0)[propName] || "string";
      if (propName === sortHistory.propName && sortHistory.isSorted != 0) {
        updateCallback(mutableArray.reverse());
        setSortHistory({ propName: propName, isSorted: -sortHistory.isSorted });
      } else {
        updateCallback(mutableArray.sort(Utils.ascSort(propName, type)));
        setSortHistory({ propName: propName, isSorted: -1 });
      }
    }
  }

  function directionIndicator(propName) {
    return (
      <>
        {sortHistory.propName == propName &&
          (sortHistory.isSorted == 1 ? (
            <i className="fas fa-caret-down" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fas fa-caret-up" style={{ fontSize: 15 }}></i>
          ))}
      </>
    );
  }

  return {
    directionIndicator,
    sortOnClick,
  };
}

export default useSort;
